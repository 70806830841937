import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import BackSvg from "../../assets/SVG/BackSvg";
import Login from "./components/Login";
import Signup from "./components/SignUp";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../store/MainSlice";
import ForgotPassword from "./components/ForgotPassword";
import langData from "../../lang/data";
import { useLocation } from "react-router-dom";

export default function LoginModal() {
  const { showLoginModal, currentLanguage } = useSelector(({ main }) => main);
  const [showLogin, setShowLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const ContainerRef = useRef();

  if (showLoginModal) {
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      setShowAnimation(true);
    }, 1);
  } else {
    document.body.style.overflow = "auto";
    return;
  }

  return (
    <div
      className={styles.background}
      onClick={(e) => {
        if (!ContainerRef.current.contains(e.target)) {
          dispatch(setShowLoginModal(false));
          setShowAnimation(false);
        }
      }}
    >
      <div
        className={`${styles.container} ${
          showAnimation && styles.openedContainer
        }`}
        ref={ContainerRef}
      >
        <div style={{
              display: window.innerWidth < 400 ? 'flex' : 'block',
              justifyContent: window.innerWidth < 400? 'center' : 'initial'  
            }}>
        <img
          src={
            currentLanguage === "hy"
              ? "https://vlv.am/public/logos/header_hy.svg"
              : "https://vlv.am/public/logos/header_ru.svg"
          }
          alt="VLV"
          className={styles.logo}
        />
        </div>
        <div className={styles.main}>
          <div className={styles.headContent}>
            <div
              className={styles.backBtn}
              onClick={() => {
                if (!showLogin) {
                  setShowLogin(true);
                } else {
                  if (forgotPassword) {
                    setForgotPassword(false);
                  } else {
                    dispatch(setShowLoginModal(false));
                    setShowAnimation(false);
                  }
                }
              }}
            >
              <BackSvg />
            </div>
            <span>
              {showLogin
                ? forgotPassword
                  ? langData.loginModal.forgotPassword[currentLanguage]
                  : langData.loginModal.login[currentLanguage]
                : langData.loginModal.signup[currentLanguage]}
            </span>
          </div>
          {showLogin &&
          pathname !==
            `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/cart` ? (
            forgotPassword ? (
              <ForgotPassword />
            ) : (
              <Login
                setShowLogin={setShowLogin}
                setForgotPassword={setForgotPassword}
              />
            )
          ) : (
            <Signup setShowLogin={setShowLogin} />
          )}
        </div>
      </div>
    </div>
  );
}
