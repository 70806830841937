import React, { useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import ShopCartSvg from "../../../../assets/SVG/ShopCartSvg";
import Product from "./components/Product/Product";
import LoadContent from "../../../../components/LoadContent";
import langData from "../../../../lang/data";
import UseCalcPrice from "../../../../helpers/UseCalcPrice";
import TreeInOneModal from "./components/Mobal";

export default function TreeInOne() {
  const { currentLanguage, currentCurrency } = useSelector(({ main }) => main);
  const { treeInOne } = useSelector(({ home }) => home);
  const [showModal, setShowModal] = useState(false);

  if (treeInOne?.error || !treeInOne) return null;
  return (
    <div className="wrapper">
      <TreeInOneModal
        modalType={showModal}
        closeModal={() => setShowModal(false)}
      />
      {!Object.keys(treeInOne || {}).length ? (
        <LoadContent
          containerStyle={{
            width: "100%",
            height: "auto",
            aspectRatio: "10 / 3.33",
          }}
        />
      ) : (
        <div className={styles.main}>
          <div className={styles.leftBlock}>
            <div className={styles.title}>
              <span style={{ color: "#d10000" }}>
                {" "}
                {langData.treeInOne.redTitle[currentLanguage]}{" "}
              </span>
              <span>{langData.treeInOne.blackTitle[currentLanguage]}</span>
            </div>
            <div className={styles.priceBlock}>
              <span className={styles.bigPrice}>
                {UseCalcPrice(treeInOne?.threeInOne?.price, currentCurrency)}
              </span>
            </div>
            <div className={styles.btnsBlock}>
              <div
                className={styles.btn}
                onClick={() => setShowModal("buyNow")}
              >
                <ShopCartSvg />
                <span>{langData.buttons.buy_now[currentLanguage]}</span>
              </div>
              <div
                className={styles.btn}
                onClick={() => setShowModal("credit")}
              >
                <span>{langData.buttons.buy_on_credit[currentLanguage]}</span>
              </div>
            </div>
          </div>
          <div className={styles.rightBlock}>
            {treeInOne?.products?.map?.((item, index) => {
              return (
                <Product
                  item={item}
                  img={treeInOne?.threeInOne?.["image_" + (index + 1)]}
                  key={index}
                  info = {index === 1}
                  last={index === 2}
                  x={treeInOne.threeInOne["img_" + (index + 1) + "_x"]}
                  y={treeInOne.threeInOne["img_" + (index + 1) + "_y"]}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
