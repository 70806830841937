import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import HeartSvg from "../../../../../../assets/SVG/HeartSvg";
import ShopCartSvg from "../../../../../../assets/SVG/ShopCartSvg";
import CompareSvg from "../../../../../../assets/SVG/CompareSvg";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardStore,
  addCompare,
  addCompares,
  addFavorites,
  addShopCartCount,
  addWishList,
  removeCompares,
  removeFavorites,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setShopCartProductAnimation,
} from "../../../../../../store/MainSlice";
import checkBase64 from "../../../../../../helpers/checkBase64";
import { credit36Month } from "../../../../../../helpers/creditCalculator";
import langData from "../../../../../../lang/data";
import GiftSvg from "../../../../../../assets/SVG/GiftSvg";
import UseCalcPrice from "../../../../../../helpers/UseCalcPrice";
import { Link } from "react-router-dom";

export default function ProductCard({
  product,
  cartChangeStyle,
  flexContainer,
  fullWidth,
}) {
  const [active, setActive] = useState(0);
  const [mouseIn, setMouseIn] = useState(false);

  const dispatch = useDispatch();
  const productImageRef = useRef();

  const { currentLanguage, currentCurrency, favorites, compares } = useSelector(
    ({ main }) => main
  );

  return (
    <div
      className={` ${flexContainer && styles.flexContainer} ${
        fullWidth ? styles.fullWidth : styles.container
      }`}
      onMouseMove={() => setMouseIn(true)}
      onMouseOut={() => setMouseIn(false)}>
      <div className={styles.topPart}>
        <div className={styles.header}>
          <Link
            to={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/brand/${product?.product?.brand?.slug}/category`}>
            <img
              className={styles.brandImg}
              src={
                checkBase64(product?.product?.brand?.logo)
                  ? "data:image/png;base64," + product?.product?.brand?.logo
                  : process.env.REACT_APP_STORAGE_URL +
                    product?.product?.brand?.logo
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.style.maxHeight = "20px";
                currentTarget.src = require("../../../../../../assets/defaultImg.png");
              }}
              alt={
                product?.product?.brand?.name +
                " " +
                product?.product?.product_name
              }
            />
          </Link>
          <HeartSvg
            className={styles.heartSvg}
            onClick={() => {
              if (
                (product?.product?.gallary_images_api?.[0] ||
                  product?.product?.thumbnail_image_source) &&
                !favorites.includes(product.id)
              ) {
                const { top, left } =
                  productImageRef.current?.getBoundingClientRect();
                dispatch(
                  setHeartProductAnimation({
                    image:
                      product?.product?.gallary_images_api?.[0]
                        ?.images_source ||
                      product?.product?.thumbnail_image_source,
                    position: {
                      top,
                      left,
                    },
                  })
                );
              }
              if (!favorites.includes(product.id)) {
                dispatch(addFavorites(product.id));
              } else {
                dispatch(removeFavorites(product.id));
              }
              dispatch(
                addWishList({
                  seller_product_id: product.id,
                })
              );
            }}
            active={favorites.includes(product?.id)}
          />
        </div>

        <div className={styles.imageBlock}>
          <div className={styles.stickersBlock}>
            {product?.product?.gift_images?.length ? (
              <div className={styles.giftBox}>
                <GiftSvg />
                <div className={styles.giftHovered}>
                  <div className={styles.giftHoveredMain}>
                    <Link
                      to={`${
                        currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/Product/${
                        product?.product?.gift_images?.[0]?.product_id
                      }`}>
                      <img
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          product?.product?.gift_images[0]?.image
                        }
                        className={styles.giftHoveredImg}
                        alt="gift"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}
            {product?.product?.sticker?.length ? (
              <img
                className={styles.stickerImg}
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  product?.product?.sticker?.[0]?.["image_" + currentLanguage]
                }
                alt={""}
              />
            ) : null}
          </div>

          <Link
            to={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/Product/${product?.id}`}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "row",
              marginBottom: "5px",
            }}
            ref={productImageRef}>
            {(product?.product?.media_ids &&
            product?.product?.media_ids?.split(",")?.length
              ? product?.product?.media_ids?.split(",")
              : product?.product?.gallary_images_api?.length
              ? product?.product?.gallary_images_api
              : Array.from({ length: 1 })
            ).map((_, index) => (
              <div
                className="esa"
                key={index}
                style={{
                  cursor: "pointer",
                  background: "transparent",
                  width: "100%",
                  height: "100%",
                  marginRight: "5px",
                  borderBottom:
                    "2px solid " +
                    (index === active ? "rgb(227, 19, 53)" : "#d9d9d9"),
                  zIndex: 99,
                  opacity: +mouseIn,
                }}
                onClick={() => setActive(index)}
                onMouseMove={() => setActive(index)}
              />
            ))}
          </Link>
          {product?.product?.media_ids ? (
            product?.product?.media_ids?.split(",")?.map((item, index) => (
              <div
                style={{
                  zIndex: index,
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  paddingBottom: "5px",
                }}
                key={index}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    opacity: active === index ? 1 : 0,
                    paddingBottom: "7px",
                  }}
                  alt={
                    product?.product?.brand?.name +
                    " " +
                    product?.product?.product_name
                  }
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    product?.product?.gallary_images_api?.find(
                      (galery) => galery.media_id == item
                    )?.images_source
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../../../../assets/defaultImg.png");
                  }}
                />
              </div>
            ))
          ) : product?.product?.gallary_images_api?.length ? (
            product?.product?.gallary_images_api?.map((item, index) => (
              <div
                style={{
                  zIndex: index,
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  paddingBottom: "5px",
                }}
                key={index}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    opacity: active === index ? 1 : 0,
                    paddingBottom: "7px",
                  }}
                  alt={
                    product?.product?.brand?.name +
                    " " +
                    product?.product?.product_name
                  }
                  src={process.env.REACT_APP_STORAGE_URL + item?.images_source}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../../../../assets/defaultImg.png");
                  }}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                zIndex: 1,
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                paddingBottom: "10px",
              }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                alt={
                  product?.product?.brand?.name +
                  " " +
                  product?.product?.product_name
                }
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  product?.product?.thumbnail_image_source
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../../../../assets/defaultImg.png");
                }}
              />
            </div>
          )}
        </div>
      </div>

      {/*PriceBlock Start*/}
      <div className={styles.descContainer}>
        <Link
          to={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/Product/${product?.id}`}
          className={styles.descriptions}>
          <div className={styles.categoryName}>
            {product?.categories?.[0]?.["name_" + currentLanguage]}
          </div>
          <p className={styles.productName}>
            {product?.product?.brand?.name +
              " " +
              product?.product?.product_name}
          </p>

          {!product?.promo_price &&
          !(
            product?.product?.online_price &&
            product?.product?.online_selling_price
          ) ? (
            <p className={styles.price}>
              {UseCalcPrice(
                product?.product?.average_price ||
                  product?.skus[0]?.selling_price,
                currentCurrency
              )}
            </p>
          ) : (
            <div className={styles.promoPriceContainer}>
              <p className={styles.newPrice}>
                {UseCalcPrice(
                  product?.promo_price ||
                    (product?.product?.online_price &&
                      product?.product?.online_selling_price),
                  currentCurrency
                )}
              </p>
              <div className={styles.oldPriceContainer}>
                <p className={styles.oldPrice}>
                  {UseCalcPrice(
                    product?.skus[0]?.selling_price,
                    currentCurrency
                  )}
                </p>

                <span className={styles.line} />
              </div>
            </div>
          )}
          {product?.product?.cashback ? (
            <div className={styles.cashbackContainer}>
              <span className={styles.cashbackText}>Cashback</span>
              <span
                className={styles.cashbackPrice}
                style={{ marginLeft: "5px" }}>
                {UseCalcPrice(product?.product?.cashback, currentCurrency)}
              </span>
            </div>
          ) : null}
          {credit36Month(
            product?.promo_price ||
              (product?.product?.online_price &&
                product?.product?.online_selling_price) ||
              product?.skus[0]?.selling_price
          ) ? (
            <>
              <div className={styles.priceMounth}>
                {UseCalcPrice(
                  credit36Month(
                    product?.promo_price ||
                      (product?.product?.online_price &&
                        product?.product?.online_selling_price) ||
                      product?.skus[0]?.selling_price
                  ),
                  currentCurrency
                )}{" "}
                / {langData.month[currentLanguage]}
              </div>

              {!!product?.product?.has_cash_price &&
                !!product?.product?.cash_price &&
                product?.product?.cash_price >= 26000 && (
                  <div className={styles.priceMounth}>
                    {`${langData.product.price[currentLanguage]} / `}
                    <span
                      className={
                        styles.priceMounthCash
                      }>{`${product?.product?.cash_price} ֏`}</span>
                  </div>
                )}
            </>
          ) : (
            <div style={{ height: "24px" }} />
          )}
        </Link>

        {/*PriceBlock END*/}

        {/* </NavLink> */}
        {/*  VOR MOBILUM KARAM RESPONSIVUTYUN TAM*/}
        <div
          className={
            !cartChangeStyle
              ? styles.btnsContainer
              : styles.btnsContainer_change
          }>
          <div
            className={styles.cardBtn}
            onClick={() => {
              if (
                product?.product?.gallary_images_api?.[0] ||
                product?.product?.thumbnail_image_source
              ) {
                const { top, left } =
                  productImageRef.current?.getBoundingClientRect();

                dispatch(
                  setShopCartProductAnimation({
                    image:
                      product?.product?.gallary_images_api?.[0]
                        ?.images_source ||
                      product?.product?.thumbnail_image_source,
                    position: {
                      top,
                      left,
                    },
                  })
                );
              }
              dispatch(
                addShopCartCount(
                  +product?.promo_price ||
                    +(
                      product?.product?.online_price &&
                      product?.product?.online_selling_price
                    ) ||
                    +product?.skus[0]?.selling_price
                )
              );
              dispatch(
                addCardStore({
                  installing: !!product?.product?.installing_price,
                  installing_count: +!!product?.product?.installing_price,
                  installing_qty: +!!product?.product?.installing_price,
                  price:
                    +product?.promo_price ||
                    +(
                      product?.product?.online_price &&
                      product?.product?.online_selling_price
                    ) ||
                    +product?.skus[0]?.selling_price,
                  qty: 1,
                  product_id: product?.skus?.[0]?.id,
                  seller_id: 1,
                  shipping_method_id: 0,
                  type: "product",
                  is_buy_now: "no",
                })
              );
            }}>
            <ShopCartSvg className={styles.cardBtnSvg} />
            <span>{langData.buttons.add_to_cart[currentLanguage]}</span>
          </div>
          <div
            className={styles.compareBtn}
            onClick={() => {
              if (
                (product?.product?.gallary_images_api?.[0] ||
                  product?.product?.thumbnail_image_source) &&
                !compares.includes(product.skus[0].id)
              ) {
                const { top, left } =
                  productImageRef.current?.getBoundingClientRect();
                dispatch(
                  setCompareProductAnimation({
                    image:
                      product?.product?.gallary_images_api?.[0]
                        ?.images_source ||
                      product?.product?.thumbnail_image_source,
                    position: {
                      top,
                      left,
                    },
                  })
                );
              }
              if (!compares.includes(product.skus[0].id)) {
                dispatch(addCompares(product.skus[0].id));
              } else {
                dispatch(removeCompares(product.skus[0].id));
              }

              dispatch(
                addCompare({
                  product_sku_id: product.skus?.[0].id,
                  data_type: product.product.product_type,
                })
              );
            }}>
            <CompareSvg active={compares.includes(product?.skus?.[0]?.id)} />
          </div>
        </div>
      </div>
    </div>
  );
}
