import { useEffect } from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { selectMain } from "store/selector";
import langData from "lang/data";
import lang from "lang/lang";
import { useSearchParams } from "react-router-dom";

const Drawer = ({
  filter,
  handleItem,
  children,
  onClick,
  isOpen,
  height,
  top,
  bottom,
  title,
}) => {
  const { currentLanguage } = useSelector(selectMain);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isOpen) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
    return () => {
      window.document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <>
          <div
            style={{
              height: height,
              top: top,
              bottom: bottom,
            }}
            className={`${styles.drawer} ${isOpen ? styles.open : ""}`}
            onClick={handleItem}>
            <div className={styles.drawerHeader}>{title}</div>
            <button
              className={styles.resetButton}
              onClick={() => {
                setSearchParams(new URLSearchParams());
              }}>
              {lang[currentLanguage].Reset}
            </button>
            {children}
            {!filter && (
              <div className={styles.drawerFooter}>
                <button onClick={handleItem}>
                  {langData?.product.save?.[currentLanguage]}
                </button>
              </div>
            )}
          </div>
          <div
            onClick={onClick}
            className={styles.drawerContainer}
          />
        </>
      )}
    </>
  );
};

export default Drawer;
