const { createSlice } = require("@reduxjs/toolkit");

const orderModalSlice = createSlice({
  name: "orderModal",
  initialState: {
    isOpenOrderModal: false,
    productId: null,
  },
  reducers: {
    setOpenOrderModal: (state, { payload }) => {
      state.isOpenOrderModal = payload.open;
      state.productId = payload?.id;
    },
  },
});
export default orderModalSlice.reducer;
export const { setOpenOrderModal } = orderModalSlice.actions;