import React from "react";
import FromToPage from "components/FromToPage";
import { useSelector } from "react-redux";
import { selectMain } from "store/selector";
import lang from "lang/lang";

const NewTitle = ({ countProduct, styles, category, searchPage }) => { 
  const { currentLanguage } = useSelector(selectMain);

  return (
    <>
      <FromToPage
        pages={[ 
          {
            label: category?.["name_" + currentLanguage] || lang[currentLanguage]?.search,
            active: true,
          },
        ]}
      />
      <div className={styles.title}>
        <span className={styles.titleText}>
         
        {category?.["name_" + currentLanguage] || lang[currentLanguage]?.search_resalt}

        </span>
        {countProduct && (
          <span className={styles.titleCount}>{countProduct}</span>
        )}
      </div>
    </>
  );
};

export default NewTitle;
