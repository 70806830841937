import { Spinner } from '@chakra-ui/react';
import StoryBuyNow from 'assets/SVG/StoryBuyNow';
import StoryCross from 'assets/SVG/StoryCross';
import StoryMute from 'assets/SVG/StoryMute';
import StoryPause from 'assets/SVG/StoryPause';
import StoryPlay from 'assets/SVG/StoryPlay';
import StoryUnmute from 'assets/SVG/StoryUnmute';
import langData from 'lang/data';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { sandBuyNowRequest } from './sandBuyNowSlice';
import './StoriesModal.css';

const StoriesModalComponent = ({
  isOpen,
  setIsOpen,
  selectedStoryIndex,
  handleFullScreenToggle,
  fullscreenDivRef,
  setLoadedStories,
}) => {
  const { get_stories, imagesPath } = useSelector(
    state => state.getStoriesSlice
  );
  const { currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRefs = useRef([]);
  const swiperRef = useRef(null);
  const [isPaused, setIsPaused] = useState({}); // track play/pause state per video
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState({}); // track loading state
  const [isVideoLoaded, setIsVideoLoaded] = useState({}); // track if video is already loaded

  const [isLoaded, setIsLoaded] = useState(false); // track if all videos are already loaded

  const videoPlayPause = useCallback(index => {
    const video = videoRefs.current[index];
    if (video) {
      if (video.paused) {
        video.play().catch(error => {
          console.log('Playback failed:', error);
        });
        setIsPaused(prev => ({ ...prev, [index]: false })); // set video as playing
      } else {
        video.pause();
        setIsPaused(prev => ({ ...prev, [index]: true })); // set video as paused
      }
    }
  }, []);

  const videoMuteUnmute = useCallback(() => {
    videoRefs.current.forEach(video => {
      if (video) {
        video.muted = !video.muted;
      }
    });
    setIsMuted(prev => !prev);
  }, []);

  const handleSlideChange = useCallback(() => {
    if (swiperRef.current) {
      const currentIndex = swiperRef.current.swiper.realIndex;
      videoRefs.current.forEach((video, index) => {
        if (video && video.src) {
          if (index === currentIndex) {
            video.play();

            setIsPaused(prev => ({ ...prev, [index]: false }));
          } else {
            video.pause();
            video.currentTime = 0;
            setIsPaused(prev => ({ ...prev, [index]: true }));
          }
        }
      });
    }
  }, [isVideoLoaded]);

  const stopAllVideos = () => {
    videoRefs.current.forEach(video => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });
  };

  const handleClose = useCallback(() => {
    stopAllVideos();
    setIsOpen(false);
    handleFullScreenToggle();
  }, [handleFullScreenToggle, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      handleSlideChange();
    }
    if (document.querySelector('jdiv')) {
      if (isOpen) {
        document.querySelector('jdiv').style.display = 'none';
      } else {
        document.querySelector('jdiv').style.display = 'block';
      }
    }
  }, [isOpen, handleSlideChange]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current?.swiper?.slideTo(selectedStoryIndex);
    }
  }, [selectedStoryIndex]);

  const handleVideoEnd = index => {
    if (swiperRef.current) {
      const totalSlides = swiperRef.current.swiper.slides.length;
      const currentIndex = swiperRef.current.swiper.realIndex;

      if (currentIndex < totalSlides - 1) {
        // Move to the next slide if it's not the last one
        swiperRef.current.swiper.slideNext();
      } else {
        // If it's the last slide, set the video to paused
        setIsPaused(prev => ({ ...prev, [index]: true }));
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoaded(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleBuyNow = useCallback(
    story_id => {
      dispatch(sandBuyNowRequest({ story_id }))
        .unwrap()
        .then(res => {
          if (res.success) {
            handleClose();
            navigate(
              `${currentLanguage !== 'hy' ? '/' + currentLanguage : ''}/cart`
            );
          }
        });
    },
    [currentLanguage, dispatch, handleClose, navigate]
  );

  const handleVideoLoad = index => {
    setIsVideoLoading(prev => ({ ...prev, [index]: false })); // Video has loaded
    setIsVideoLoaded(prev => ({ ...prev, [index]: true })); // Mark video as loaded

    setLoadedStories(prev => [...prev, index]);
  };

  const handleVideoLoadStart = index => {
    if (!isVideoLoaded[index]) {
      setIsVideoLoading(prev => ({ ...prev, [index]: true })); // Video is loading
    }
  };

  if (!isOpen) {
    stopAllVideos();
  }

  return (
    <div
      className={`StoriesModal ${isOpen ? 'is-open' : ''}`}
      ref={fullscreenDivRef}
    >
      <Swiper
        className={'StoriesContainer'}
        direction={'vertical'}
        ref={swiperRef}
        speed={600}
        grabCursor={true}
        onSlideChange={handleSlideChange}
        initialSlide={selectedStoryIndex}
      >
        {get_stories?.map((item, index) => {
          const nameTrimmed = item?.[`name_${currentLanguage}`]?.trim() || null;
          const descriptionTrimmed =
            item?.[`description_${currentLanguage}`]?.trim() || null;

          return (
            <SwiperSlide className={'StoryContentParent'} key={item.id}>
              {isVideoLoading[index] && (
                <Spinner
                  size='xl'
                  color={'white'}
                  style={{
                    position: 'absolute',
                    left: 'calc(50% - 25px)',
                    top: '50%',
                    zIndex: 2,
                    width: 50,
                    height: 50,
                  }}
                />
              )}
              {item.image === 1 ? (
                <img
                  src={imagesPath + '/' + item.images}
                  alt='story image_or_video'
                  className={'StoryVideoImageContent'}
                />
              ) : index === 0 ||
                isVideoLoaded[index] ||
                isVideoLoaded[index - 1] ? (
                <video
                  ref={el => (videoRefs.current[index] = el)}
                  src={`${imagesPath}${item.video_path}`}
                  className={'StoryVideoImageContent'}
                  muted={isMuted}
                  playsInline={true}
                  crossOrigin='anonymous'
                  autoPlay
                  preload='auto'
                  onLoadedData={() => handleVideoLoad(index)}
                  onLoadStart={() => handleVideoLoadStart(index)}
                  onEnded={() => handleVideoEnd(index)}
                ></video>
              ) : (
                ''
              )}
              {!nameTrimmed && !descriptionTrimmed ? (
                <div />
              ) : (
                <div className='StoryInfoContainer'>
                  <Link to={item?.url}>
                    <div className='StoryTextContainer'>
                      {nameTrimmed && (
                        <h2 className='StoryTitle'>{nameTrimmed}</h2>
                      )}
                      {descriptionTrimmed && (
                        <p className='StoryDescription'>{descriptionTrimmed}</p>
                      )}
                    </div>
                  </Link>
                  {item.buy_now ? (
                    <button
                      className='StoryBuyNow'
                      onClick={() => handleBuyNow(item.id)}
                    >
                      <StoryBuyNow className='StoryBuyNowIcon' />
                      {langData.buttons.buy_now[currentLanguage]}
                    </button>
                  ) : null}
                </div>
              )}

              <div className={'StoriesRightButtons'}>
                <button
                  className={'StoriesControlButtons'}
                  onClick={handleClose}
                >
                  <StoryCross className={'StoryIcon'} />
                </button>
                <div className={'StoriesBottomControlsContainer'}>
                  {item.image === 0 && (
                    <>
                      <button
                        className={'StoriesControlButtons'}
                        onClick={() => videoPlayPause(index)}
                      >
                        {!isPaused[index] ? (
                          <StoryPause className={'StoryIcon'} />
                        ) : (
                          <StoryPlay className={'StoryIcon'} />
                        )}
                      </button>
                      <button
                        className={'StoriesControlButtons'}
                        onClick={videoMuteUnmute}
                      >
                        {!isMuted ? (
                          <StoryUnmute className={'StoryIcon'} />
                        ) : (
                          <StoryMute className={'StoryIcon'} />
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export const StoriesModal = memo(StoriesModalComponent);
