import React from "react";
import styles from "./styles.module.css";
import PhoneSvg from "assets/SVG/PhoneSvg";
import MessageSvg from "components/SubscribeModal/assets/MessageSvg";
import UserSvg from "assets/SVG/UserSvg";
import langData from "lang/data";
import { useSelector } from "react-redux";
import MapMarkerSvg from "components/Map/assets/MapMarkerSvg";

const PersonalCart = ({
  profilePageInfo,
  setOtherUser,
  activeUser,
  setActiveUser,
}) => {
  const { currentLanguage } = useSelector(({ main }) => main);

  const handleActiveUser = (item) => {
    setActiveUser(item);
    setOtherUser(false);
  };

  return (
    <div className={styles.personalCart}>
      <div className={styles.personalCartContainer}>
        <div className={styles.personalCartHeader}>
          <h3>{langData?.loginModal?.personalCartTitle?.[currentLanguage]}</h3>
          <button
            onClick={() => {
              setOtherUser(true);
              setActiveUser("");
            }}>
            {langData?.loginModal?.personalCartButton?.[currentLanguage]}
          </button>
        </div>
        {Object.keys(profilePageInfo?.addresses || {})?.map((key, index) => {
          let item = profilePageInfo?.addresses[key];
          return (
            <div
              onClick={() => handleActiveUser(item)}
              className={
                activeUser?.id === item?.id
                  ? ` ${styles.personalCartUser} ${styles.acitve}`
                  : styles.personalCartUser
              }>
              <div
                className={
                  activeUser?.id === item?.id
                    ? `${styles.checked} ${styles.checkedActive}`
                    : styles.checked
                }>
                {/* <div></div> */}
              </div>
              <div className={styles.personalCartImg}>
                <UserSvg />
              </div>
              <div className={styles.personalInfo}>
                <h4>{`${item?.name} ${item?.surname}`}</h4>
                <div className={styles.personalContact}>
                  <p>
                    <PhoneSvg />
                    {item?.phone}
                  </p>
                  <p>
                    <MessageSvg />
                    {item?.email}
                  </p>
                </div>
                <p>
                  <img
                    src={
                      "https://vlv.am/public/uploads/images/pages/637b610147ae5.svg"
                    }
                  />
                  {item?.address}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PersonalCart;
