import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectBrand, selectCategory, selectMain } from "store/selector";
import { useParams, useSearchParams } from "react-router-dom";
import BanerSlider from "components/BanerSlider";
import LoadContent from "components/LoadContent";
import { categoryThunk } from "store/slices/category/thunk";
import ProductList from "./components/productList";
import Attributes from "./components/Attribute";
import { brandCategoryThunk } from "store/slices/brandSlice/thunk";
import Title from "./components/Title";
import { setPending } from "store/MainSlice";
import { Link } from "react-router-dom";
import {
  clearBrand,
  clearBrandCategory,
} from "store/slices/brandSlice/brandSlice";
import { setCategory } from "store/slices/category/categorySlice";
import lang from "lang/lang";

const Category = () => {
  const scrollElement = useRef(null);

  const { category, loading } = useSelector(selectCategory);
  const { brandCategoy, categoryLoading } = useSelector(selectBrand);
  const { currentLanguage } = useSelector(selectMain);

  const [recordPage, setRecordPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [load, setLoad] = useState(false);

  const { categoryId } = useParams();

  const dispatch = useDispatch();

  const handleMoreProducts = (number) => {
    setLoad(true);
    setCurrentPage(1);
    searchParams.set("p", number);
    setSearchParams(searchParams);
  };
  const handleMoreProduct = () => {
    setLoad(true);
    setCurrentPage(1);
    searchParams.set("p", +category?.products?.length + recordPage);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    dispatch(
      categoryThunk({
        categoryId,
        productsParams: {
          p: searchParams.get("p"),
          page: searchParams.get("page"),
          s: searchParams.get("s"),
          a: searchParams.get("a"),
          b: searchParams.get("b"),
          c: searchParams.get("c"),
          d: searchParams.get("d") || "",
          mn: searchParams.get("mn"),
          mx: searchParams.get("mx"),
          st: searchParams.get("st"),
        },
        isDynamic: searchParams.get("id"),
        searchPage: searchParams.get("sp"),
      })
    );
    dispatch(setPending(false));
  }, [categoryId, dispatch, currentPage, searchParams]);

  useEffect(() => {
    dispatch(setCategory({}));
    dispatch(clearBrand());
    dispatch(clearBrandCategory());
    dispatch(brandCategoryThunk(categoryId));
  }, [categoryId, dispatch]);

  useEffect(() => {
    if (category?.searchPage) {
      searchParams.set("sp", category?.searchPage);
      setSearchParams(searchParams);
    }
    if (!load && searchParams.size) {
      scrollElement?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [
    category,
    currentPage,
    load,
    searchParams,
    searchParams.size,
    setSearchParams,
  ]);

  useEffect(() => {
    if (!searchParams.size) {
      window.scrollTo(0, 0);
    }
  }, [categoryId, searchParams.size]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(searchParams?.get("page"));
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (categoryLoading) {
      dispatch(setPending(true));
    } else {
      setTimeout(() => {
        dispatch(setPending(false));
      }, 150);
    }
  }, [categoryLoading, dispatch]);
  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    setBannerVisible(false);
  }, [categoryId]);

  useEffect(() => {
    setBannerVisible(true);
  }, [category]);

  return (
    <div
      className="wrapper"
      style={{ overflowX: "visible" }}>
      <div className={styles.category}>
        <Title
          brandCategoy={brandCategoy}
          styles={styles}
          category={category}
          searchPage={searchParams.get("sp")}
        />

        <div className={styles.categorySlider}>
          <BanerSlider
            bannerSliders={category?.category_slider_image}
            containerStyle={{
              margin: 0,
              padding: 0,
              position: "relative",
            }}
            loop
            loading={categoryLoading}
          />
        </div>

        {categoryLoading ? (
          <LoadContent containerClassName={styles.categoryBrandLoad} />
        ) : (
          <>
            {brandCategoy?.sub_categories?.length > 1 && (
              <div className={styles.categoryBrand}>
                {!!brandCategoy?.sub_categories?.length &&
                  brandCategoy?.sub_categories?.map((item, index) => (
                    <Link
                      to={`${
                        currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/filter/${item?.slug}${
                        brandCategoy?.category?.brand
                          ? `?b=${brandCategoy.category.brand}`
                          : ""
                      }`}>
                      <div
                        key={index}
                        className={styles?.categoryBrandItem}>
                        <div className={styles.categoryItemTop}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              item?.category_image?.image
                            }
                            alt="product"
                          />
                        </div>
                        <div className={styles.categoryItemBottom}>
                          <span>{item?.["name_" + currentLanguage]}</span>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </>
        )}
        <div
          className={styles.example}
          ref={scrollElement}
        />
        <Attributes
          styles={styles}
          setCurrentPage={setCurrentPage}
          category={category}
          loading={loading}
        />
        {bannerVisible && category ? (
          <ProductList
            load={load}
            styles={styles}
            handlePagination={(e) => {
              searchParams.set("page", e);
              setSearchParams(searchParams);
              setCurrentPage(e);
            }}
            currentPage={currentPage}
            handleMoreProducts={handleMoreProducts}
            handleMoreProduct={handleMoreProduct}

            recordPage={recordPage}
            setRecordPage={setRecordPage}
            category={category}
            loading={loading}
          />
        ) : !categoryLoading && !bannerVisible && !category ? (
          <span>{lang[currentLanguage].product_not_found}</span>
        ) : null}
      </div>
    </div>
  );
};

export default Category;
