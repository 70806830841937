import React, { useEffect } from "react";
import styles from "./style.module.css";
import CloseSvg from "assets/SVG/CloseSvg";
import FacebookSvg from "assets/SVG/FacebookSvg";
import InstagramSvg from "assets/SVG/InstagramSvg";
import WhatsappSvg from "assets/SVG/WhatsappSvg";
import ViberSvg from "assets/SVG/ViberSvg";
import Currency from "../Currency";
import PhoneSvg from "assets/SVG/PhoneSvg";
import langData from "lang/data";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function MobileMenu({ openMenu, setOpenMenu }) {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openMenu]);

  const menuData = [
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/contacts`,
      label: langData.header.menu.Our_shops,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/blog`,
      label: langData.header.menu.Blog,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/job`,
      label: langData.header.menu.Jobs,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/about-us`,
      label: langData.header.menu.About_us,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/delivery`,
      label: langData.header.menu.Delivery,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/credit`,
      label: langData.header.menu.Credit,
    },

    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/contacts`,
      label: langData.header.menu.Contacts,
    },
  ];
  return (
    <div className={`${styles.container} ${openMenu && styles.openMenu}`}>
      <div className={styles.headContent}>
        <div className={styles.socIconsContainer}>
          <a href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d">
            <FacebookSvg className={styles.socIcon} />
          </a>
          <a href="https://www.instagram.com/vlv_centre/">
            <InstagramSvg className={styles.socIcon} />
          </a>
          <a href="https://wa.me/37491296655">
            <WhatsappSvg className={styles.socIcon} />
          </a>
          <a href="viber://chat?number=+37491296655/">
            <ViberSvg className={styles.socIcon} />
          </a>
        </div>
        <Currency />
        <CloseSvg
          width="20px"
          height="20px"
          className={styles.closeBtn}
          onClick={() => setOpenMenu(false)}
        />
      </div>
      <div>
        <a
          className={styles.phone}
          href="tel:010349944">
          <PhoneSvg
            width="16"
            height="16"
          />
          010-34-99-44
        </a>
      </div>
      <div className={styles.menuContainer}>
        {menuData.map(({ href, label }) => (
          <Link
            key={label?.en}
            onClick={() => setOpenMenu(false)}
            to={href}
            className={styles.menuItem}>
            {label?.[currentLanguage]}
          </Link>
        ))}
      </div>
    </div>
  );
}
