import React, { useEffect, useState } from "react";
import styles from "./OrderModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setOpenOrderModal } from "./state/orderModalSlice";
import { sendOrderDataRequest } from "./state/sendOrderDataSlice";
import { setOpenSuccessOrder } from "./state/successOrderSlice";

const OrderModal = () => {
  const dispatch = useDispatch();
  const { isOpenOrderModal, productId } = useSelector(
    ({ orderModalSlice }) => orderModalSlice
  );
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone_number: "(+374) ",
  });
  const {
    loading,
    email_error,
    phone_number_error,
    name_error,
    surname_error,
  } = useSelector(({ sendOrderDataSlice }) => sendOrderDataSlice);

  const handleChangeFormData = (event) => {
    const { value, name } = event.target;

    if (name === "phone_number") {
      setFormData((prev) => ({
        ...prev,
        phone_number: validatePhoneNumber(value),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validatePhoneNumber = (value) => {
    if (value.length <= "(+374) ".length) {
      return "(+374) ";
    }

    let cleanValue = value.replace("(+374) ", "").replace(/[^\d]/g, "");

    if (cleanValue.length > 2 && cleanValue.length <= 4) {
      cleanValue = cleanValue.slice(0, 2) + "-" + cleanValue.slice(2);
    } else if (cleanValue.length > 4 && cleanValue.length <= 6) {
      cleanValue =
        cleanValue.slice(0, 2) +
        "-" +
        cleanValue.slice(2, 4) +
        "-" +
        cleanValue.slice(4);
    } else if (cleanValue.length > 6) {
      cleanValue =
        cleanValue.slice(0, 2) +
        "-" +
        cleanValue.slice(2, 4) +
        "-" +
        cleanValue.slice(4, 6) +
        "-" +
        cleanValue.slice(6);
    }

    return "(+374) " + cleanValue;
  };

  const handleSubmitData = (event) => {
    event.preventDefault();

    dispatch(
      sendOrderDataRequest({
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        phone_number: formData.phone_number,
        product_id: String(productId).trim(),
      })
    ).then(({ payload }) => {
      if (payload?.message === "Ordered Product successfully submitted") {
        dispatch(setOpenOrderModal({ open: false }));
        dispatch(setOpenSuccessOrder(true));
      }
    });
  };

  useEffect(() => {
    if (isOpenOrderModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpenOrderModal]);

  return isOpenOrderModal ? (
    <section className={styles.background}>
      <form
        className={styles.container}
        onSubmit={handleSubmitData}>
        <div
          className={styles.close}
          onClick={() => dispatch(setOpenOrderModal({ open: false }))}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1L16 16M16 1L1 16"
              stroke="#282828"></path>
          </svg>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.title}>Պատվեր</div>
          <h2 className={styles.description}>
            Լրացրեք հետևյալ դաշտերը և մենք կապ կհաստատենք Ձեզ հետ մանրամասները
            քննարկելու համար։
          </h2>
          <label
            htmlFor="order_product_name"
            className={styles.orderProductName}>
            Անուն
            <input
              type="text"
              className={styles.name}
              name="name"
              value={formData.name}
              onChange={handleChangeFormData}
            />
            <span style={{ color: "#d10000" }}>{name_error}</span>
          </label>
          <label
            htmlFor="order_product_surname"
            className={styles.orderProductName}>
            Ազգանուն
            <input
              type="text"
              className={styles.surname}
              name="surname"
              value={formData.surname}
              onChange={handleChangeFormData}
            />
            <span style={{ color: "#d10000" }}>{surname_error}</span>
          </label>
          <label
            htmlFor="order_product_email"
            className={styles.orderProductName}>
            Էլ․ փոստ
            <input
              type="email"
              className={styles.email}
              name="email"
              value={formData.email}
              onChange={handleChangeFormData}
            />
            <span style={{ color: "#d10000" }}>{email_error}</span>
          </label>
          <label
            htmlFor="phone_am"
            className={styles.orderProductName}>
            Հեռախոսահամար
            <input
              type="text"
              className={styles.phone}
              name="phone_number"
              value={formData.phone_number}
              maxLength="18"
              onChange={handleChangeFormData}
            />
            <span style={{ color: "#d10000" }}>{phone_number_error}</span>
          </label>
        </div>
        <button
          className={styles.button}
          disabled={loading}
          type={"submit"}>
          Պատվեր
        </button>
      </form>
    </section>
  ) : null;
};

export default OrderModal;