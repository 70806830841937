import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../../components/LoadContent";
import ProductsWithSlide from "../../components/ProductsWithSlide";
import { Link, useParams } from "react-router-dom";
import FromToPage from "../../components/FromToPage";
import { postVikas } from "../../store/Vikas/VikasFetch";
import BrandBanerSlider from "./components/brandBanerSlider";
import BrandCategoriesItem from "./components/brandCategoriesItem";
import SmallSlider from "./components/smallSlider";
import SmallCategories from "./components/smallCategories";
import BrandBanner from "./components/BrandBanner";
import ProductCard from "../../components/ProductCard";

const Vikas = () => {
  const [activeCategory, setActiveCategory] = useState();
  const { isLoading, error, vikas } = useSelector((state) => state.VikasSlice);
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  const { name } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!vikas.length) {
      dispatch(postVikas(name));
    }
  }, [name]);
  useEffect(() => {
    setActiveCategory(vikas?.products_slider?.[0].product?.categories?.[0]?.id);
  }, [vikas]);

  return (
    <div className="wrapper">
      <FromToPage pages={[{ label: `${name}`, value: "/job", active: true }]} />
      {!isLoading ? (
        <>
          <BrandBanerSlider bannerSliders={vikas.slider1} />
        </>
      ) : (
        <LoadContent containerClassName={style.brand_banners_loader} />
      )}

      {!isLoading ? (
        <>
          {vikas.info && vikas.info["descr1_" + currentLanguage] ? (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: vikas.info["descr1_" + currentLanguage],
                }}
              />
            </>
          ) : null}
        </>
      ) : (
        <>
          {Array.from({ length: 3 }).map((_, index) => {
            return (
              <LoadContent
                key={index}
                containerClassName={style.brand_text_loader}
              />
            );
          })}
        </>
      )}

      {!isLoading ? (
        <div className={style.brands_sub_title}>
          {vikas.info && vikas.info["subtitle1_" + currentLanguage] ? (
            <>
              <h3>{vikas.info["subtitle1_" + currentLanguage]}</h3>
            </>
          ) : null}
        </div>
      ) : (
        <LoadContent containerClassName={style.brands_sub_title_loader} />
      )}

      {!isLoading ? (
        <>
          {vikas.categories1 ? (
            <>
              <BrandCategoriesItem list={vikas.categories1} />
            </>
          ) : null}
        </>
      ) : (
        <>
          <div className={style.brand_loader_categories}>
            {Array.from({ length: 5 }).map((_, index) => {
              return (
                <LoadContent
                  key={index}
                  containerClassName={style.brand_loader_categories_item}
                />
              );
            })}
          </div>
        </>
      )}
      <div className={style.brand__small_slider_banners}>
        {!isLoading ? (
          <>
            <div className={style.small_categories_list}>
              {vikas?.slider2 ? (
                <>
                  <SmallSlider
                    currentLanguage={currentLanguage}
                    slider={vikas.slider2}
                  />
                </>
              ) : null}
              {vikas.categories2 ? (
                <>
                  {vikas.categories2
                    .reduce((acc, item, index) => {
                      if (index % 2 === 0) {
                        acc.push([]);
                      }
                      acc[acc.length - 1].push(
                        <SmallCategories
                          key={index}
                          item={item}
                          currentLanguage={currentLanguage}
                        />
                      );
                      return acc;
                    }, [])
                    .map((group, index) => (
                      <div
                        className={style.small_categories_item_block}
                        key={index}>
                        {group}
                      </div>
                    ))}
                </>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <LoadContent containerClassName={style.brand_mini_slider_loader} />
            <LoadContent containerClassName={style.brand_mini_slider_loader} />
          </>
        )}
      </div>

      <div className={style.brand_banners_list}>
        {!isLoading ? (
          <>
            {vikas?.banners ? (
              <>
                <BrandBanner
                  img={vikas?.baners["baner_1_" + currentLanguage]}
                  href={vikas?.banners.banner_1_href}
                />

                <BrandBanner
                  img={vikas?.baners["baner_2_" + currentLanguage]}
                  href={vikas?.banners.banner_2_href}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            {Array.from({ length: 2 }).map((_, index) => {
              return (
                <LoadContent containerClassName={style.brand_banners_loader} />
              );
            })}
          </>
        )}
      </div>

      <div className={style.vikas_catalog_block}>
        <div className={style.vikas_catalog_images}>
          {!isLoading ? (
            <>
              {vikas?.banners && vikas?.baners["baner_3_" + currentLanguage] ? (
                <>
                  <BrandBanner
                    img={vikas?.baners["baner_3_" + currentLanguage]}
                    href={vikas?.banners.banner_3_href}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <LoadContent
                containerClassName={style.brand_mini_banners_loader}
              />
            </>
          )}
          {!isLoading ? (
            <>
              {vikas?.banners && vikas?.baners["baner_4_" + currentLanguage] ? (
                <>
                  <BrandBanner
                    img={vikas?.baners["baner_4_" + currentLanguage]}
                    href={vikas?.banners.banner_4_href}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <LoadContent
                containerClassName={style.brand_mini_banners_loader}
              />
            </>
          )}
        </div>
        <div className={style.vikas_catalog_list}>
          {!isLoading ? (
            <div className={style.vikas_catalog_list_title}>
              {vikas.info && vikas.info["subtitle2_" + currentLanguage] ? (
                <>
                  <h3>{vikas.info["subtitle2_" + currentLanguage]}</h3>
                </>
              ) : null}
            </div>
          ) : (
            <LoadContent containerClassName={style.brand_text_loader_title} />
          )}

          {!isLoading ? (
            <>
              {vikas.categories3 ? (
                <>
                  <div className={`${style.brand_categories_item} scrollBar`}>
                    {vikas?.categories3.map((item, index) => {
                      return (
                        <span
                          onClick={() => {
                            setActiveCategory(item.id);
                          }}
                          key={index}
                          className={`${style.brands_simple} ${
                            item.id == activeCategory &&
                            style.brands_simple_active
                          }`}>
                          {item["name_" + currentLanguage]}
                        </span>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <>
              <div className={style.brand_loader_categories}>
                {Array.from({ length: 5 }).map((_, index) => {
                  return (
                    <LoadContent
                      key={index}
                      containerClassName={style.brand_loader_categories_item}
                    />
                  );
                })}
              </div>
            </>
          )}

          {!isLoading ? (
            <div className={style.catalog_list_cards}>
              {vikas.products_slider?.map((product, index) => {
                if (product?.product?.categories[0].id === activeCategory)
                  return (
                    <ProductCard
                      containerStyle={style}
                      flexContainer={window.innerWidth <= 1024}
                      product={product}
                    />
                  );
              })}
              {/* <Link
                to={`${
                  currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/search/brand?vikass&ct=${selectedFilterId}`}
                className={style.more_prods}
              >
                {lang[currentLanguage].load_more}
              </Link> */}
            </div>
          ) : (
            <div className={style.catalog_list_cards}>
              {Array.from({ length: 12 }).map((_, index) => {
                return (
                  <LoadContent
                    containerClassName={style.catalog_list_cards_loader_item}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>

      {!isLoading ? (
        <div className={style.last_info_brand}>
          {vikas.info && vikas.info["descr2_" + currentLanguage] ? (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: vikas.info["descr2_" + currentLanguage],
                }}
              />
            </>
          ) : null}
        </div>
      ) : (
        <>
          <LoadContent containerClassName={style.last_info_brand_loader} />
        </>
      )}
    </div>
  );
};
export default Vikas;
