import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const sendOrderDataRequest = createAsyncThunk(
  "orderSend/sendOrderDataRequest",
  async (
    { name, surname, email, phone_number, product_id },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}orderable-product/store`,
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          _token: process.env.TOKEN,
          name,
          surname,
          email,
          phone_number,
          product_id,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
const sendOrderDataSlice = createSlice({
  name: "orderSend",
  initialState: {
    loading: false,
    email_error: "",
    phone_number_error: "",
    name_error: "",
    surname_error: "",
  },
  extraReducers: (builder) =>
    builder
      .addCase(sendOrderDataRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendOrderDataRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(sendOrderDataRequest.rejected, (state, { payload }) => {
        const { email, name, surname, phone_number } = payload?.error;
        state.loading = false;
        state.email_error = email;
        state.phone_number_error = phone_number;
        state.name_error = name;
        state.surname_error = surname;
      }),
});
export default sendOrderDataSlice.reducer;