import React, { useEffect } from "react";
import CartHeader from "./components/CartHeader";
import style from "./style.module.css";
import CartNull from "./components/CartNull";
import Cart from "./components/Cart";
import { useDispatch, useSelector } from "react-redux";
import { getCartPageProducts } from "../../store/MainSlice";
import CartLoader from "./components/CartLoader";
import lang from "../../lang/lang";
import FromToPage from "../../components/FromToPage";

const CartContent = () => {
  const { cartPageProducts, currentLanguage, shopCartCount } = useSelector(
    ({ main }) => main
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartPageProducts());
  }, [
    dispatch,
    // shopCartCount.count
  ]);

  return (
    <>
      <div className={style.cart_content}>
        <CartHeader />
        <div
          className="wrapper"
          style={{ marginBottom: 0 }}>
          <div className={style.cart_main}>
            <FromToPage
              langs={currentLanguage}
              pages={[{ label: lang[currentLanguage]?.cart, active: true }]}
            />

            {!Object.keys(cartPageProducts || {}).length ? (
              <CartLoader />
            ) : cartPageProducts?.error ? (
              <CartNull />
            ) : (
              <Cart />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CartContent;
