import React from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import langData from "../../../../lang/data";
import { Link } from "react-router-dom";
import UseGetDate from "../../../../helpers/UseGetDate";

export default function Blog({ btn, title }) {
  const { blogs } = useSelector(({ home }) => home);
  const { currentLanguage } = useSelector(({ main }) => main);
  return (
    <div className="wrapper">
      <div className={styles.titleBlock}>
        <h3 className="title">
          {title == "default"
            ? langData.titles.Blog[currentLanguage]
            : langData.titles.BlogTitleSecond[currentLanguage]}
        </h3>
        {btn && (
          <Link
            to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/blog`}
          >
            <span className={styles.moreBtn}>
              {langData.buttons.view_all[currentLanguage]}
            </span>
          </Link>
        )}
      </div>

      <div className={styles.main}>
        {!blogs.length
          ? Array.from({ length: 4 }).map((_, index) => (
              <div className={styles.blogItem} key={index}>
                <LoadContent containerClassName={styles.loadContentImg} />
                <LoadContent containerClassName={styles.loadContentTitle} />
                <LoadContent containerClassName={styles.loadContentDate} />
              </div>
            ))
          : blogs.map((item, index) => {
              return (
                <Link
                  to={`${
                    currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/blog/${item.slug}`}
                  className={styles.blogItem}
                  key={index}
                >
                  <img
                    className={styles.blogItemImg}
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      item["image_url_" + currentLanguage]
                    }
                    alt={item.title_en}
                  />
                  <h4 className={styles.blogItemTitle}>
                    {item?.["title_" + currentLanguage]?.substring(0, 35)}...
                  </h4>
                  <span className={styles.blogItemDate}>
                    {UseGetDate(item?.created_at, currentLanguage)}
                  </span>
                </Link>
              );
            })}
      </div>
    </div>
  );
}
