import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import MagnifierMinus from "assets/SVG/MagnifierMinus";
import MagnifierPlus from "assets/SVG/MagnifierPlus";
import useWindowSize from "components/useWindowSize";

const ZoomImage = ({
  activeImg,
  imgs,
  onOpenModal,
  isOverImage,
  zoomResetFlag,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const { width: windowWidth } = useWindowSize();
  const imgRef = useRef(null);
  const dragStartRef = useRef({ x: 0, y: 0 });
  const wasDraggedRef = useRef(false);

  // Reset zoom level when zoomResetFlag changes
  useEffect(() => {
    setZoomLevel(1);
    setPosition({ x: 0, y: 0 });
  }, [zoomResetFlag]);

  const handleZoomChange = (newZoomLevel) => {
    if (newZoomLevel <= 1) {
      setPosition({ x: 0, y: 0 });
    }
    setZoomLevel(newZoomLevel);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (zoomLevel <= 1) return;
    setIsDragging(true);
    imgRef.current.style.cursor = "grabbing";
    dragStartRef.current = { x: e.clientX, y: e.clientY };
    wasDraggedRef.current = false;
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition((prev) => ({
      x: prev.x + e.movementX,
      y: prev.y + e.movementY,
    }));
    wasDraggedRef.current = true;
  };

  const handleMouseUp = (e) => {
    if (zoomLevel <= 1) return;
    setIsDragging(false);
    imgRef.current.style.cursor = "grab";

    const dragDistance = Math.sqrt(
      Math.pow(e.clientX - dragStartRef.current.x, 2) +
        Math.pow(e.clientY - dragStartRef.current.y, 2)
    );

    if (dragDistance < 5 && !wasDraggedRef.current) {
      onOpenModal(activeImg);
    }
  };

  return (
    <>
      <div
        className={styles.zoomImageWrapper}
        ref={imgRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onClick={(e) => {
          if (zoomLevel <= 1 && !wasDraggedRef.current) {
            onOpenModal(activeImg);
          }
        }}
        style={{
          overflow: "hidden",
          position: "relative",
          cursor: "grab",
        }}>
        <img
          src={
            process.env.REACT_APP_STORAGE_URL + imgs[activeImg]?.images_source
          }
          alt=""
          style={{
            transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
            transformOrigin: "center center",
            transition: "transform 0.1s",
            width: "100%",
            maxHeight: "100%",
            height: "100%",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = require("../../../../../../assets/defaultImg.png");
          }}
        />
      </div>
      {windowWidth > 768 && isOverImage ? (
        <ZoomSlider
          zoomLevel={zoomLevel}
          onZoomChange={handleZoomChange}
        />
      ) : null}
    </>
  );
};

export default ZoomImage;

const ZoomSlider = ({ zoomLevel, onZoomChange }) => {
  return (
    <div
      className={styles.zoomControl}
      onClick={(e) => e.stopPropagation()}>
      <button
        onClick={() => onZoomChange(zoomLevel - 0.1)}
        disabled={zoomLevel <= 1}>
        <MagnifierMinus />
      </button>
      <input
        type="range"
        min="1"
        max="2"
        step="0.1"
        value={zoomLevel}
        onChange={({ target }) => onZoomChange(parseFloat(target.value))}
      />
      <button
        onClick={() => onZoomChange(zoomLevel + 0.1)}
        disabled={zoomLevel >= 3}>
        <MagnifierPlus />
      </button>
    </div>
  );
};
