import React from "react";

const GroupProductPlusIcon = (props) => (
  <svg
    // width="58"
    // height="57"
    viewBox="0 0 58 57"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="28.8016"
      cy="28.5944"
      r="28.3279"
      fill="#E31335"
    />
    <path
      d="M29.528 39.4899V27.8682M29.528 27.8682V16.2465M29.528 27.8682H17.9062M29.528 27.8682H41.1497"
      stroke="white"
      strokeWidth="2.90543"
      strokeLinecap="round"
    />
  </svg>
);

export default GroupProductPlusIcon;
