import LoadContent from 'components/LoadContent';
import useWindowSize from 'components/useWindowSize';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getStoriesRequest } from './getStoriesSlice';
import './Stories.css';

export const Stories = ({
  loadedStories,
  setSelectedStoryIndex,
  setIsOpen,
  handleFullScreenToggle,
}) => {
  const dispatch = useDispatch();
  const { get_stories, imagesPath, loading, stories_title } = useSelector(
    state => state.getStoriesSlice
  );
  const { currentLanguage } = useSelector(({ main }) => main);
  const { width: windowWidth } = useWindowSize();
  useEffect(() => {
    dispatch(getStoriesRequest());
  }, [dispatch]);

  const handleStoryClick = index => {
    setSelectedStoryIndex(index);
    setIsOpen(true);
    handleFullScreenToggle();
  };

  return (
    <div className='wrapper'>
      <h3 className={'title'}>{stories_title[`title_${currentLanguage}`]}</h3>
      {windowWidth < 768 ? (
        <div className='stories__list'>
          {loading ? (
            <div className='stories__wrapper scrollRow'>
              {Array.from({ length: 9 }).map((_, ind) => (
                <LoadContent containerClassName={'StoryLoader'} key={ind} />
              ))}
            </div>
          ) : (
            <div className='stories__wrapper scrollRow'>
              {get_stories.slice(0, 16)?.map((item, index) => {
                if (loadedStories.indexOf(index) > -1) {
                  return (
                    <div
                      key={index}
                      className='swiper-slide'
                      onClick={() => handleStoryClick(index)}
                    >
                      <div>
                        <img src={imagesPath + '/' + item.main_photo} alt='' />
                      </div>
                      <p className={'StoriesTitle'}>
                        {item['title_' + currentLanguage]}
                      </p>
                    </div>
                  );
                } else
                  return (
                    <LoadContent
                      containerClassName={'StoryLoader'}
                      key={index}
                    />
                  );
              })}
            </div>
          )}
        </div>
      ) : (
        <Swiper
          navigation={windowWidth > 425 ? true : false}
          slidesPerView={'auto'}
          spaceBetween={windowWidth > 425 ? 30 : 20}
          modules={[Navigation]}
          loop={false}
          className='stories__wrapper'
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                gap: windowWidth > 425 ? 30 : 20,
              }}
            >
              {Array.from({ length: 9 }).map((_, ind) => (
                <LoadContent containerClassName={'StoryLoader'} key={ind} />
              ))}
            </div>
          ) : (
            get_stories.slice(0, 16)?.map((item, index) => {
              if (loadedStories.indexOf(index) > -1) {
                return (
                  <SwiperSlide
                    key={index}
                    onClick={() => handleStoryClick(index)}
                  >
                    <div>
                      <img src={imagesPath + '/' + item.main_photo} alt='' />
                    </div>
                    <p className={'StoriesTitle'}>
                      {item['title_' + currentLanguage]}
                    </p>
                  </SwiperSlide>
                );
              } else {
                return (
                  <SwiperSlide
                    key={index}
                    onClick={() => handleStoryClick(index)}
                  >
                    <LoadContent containerClassName={'StoryLoader'} />
                  </SwiperSlide>
                );
              }
            })
          )}
        </Swiper>
      )}
    </div>
  );
};
