import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";
import lang from "../../../../lang/lang";
import { postCreditModal2 } from "../../../../store/MainSlice";
const sendedFiles = [];
export default function Agreement({
  fullName,
  setPending,
  agreementInfo,
  back,
  close,
  setThanksModal,
}) {
  const [aggreCheckBox, setAggreCheckBox] = useState(false);
  const [aggreCheckBox2, setAggreCheckBox2] = useState(false);
  const [passportInput, setPassportInput] = useState("");

  const [showCreditInfoBlock, setShowCreditInfoBlock] = useState(false);
  const [uploadedFile, setUpladedFile] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [uploadedFileError, setUpladedFileError] = useState(false);
  const [passportInputError, setPassportInputError] = useState(false);
  const [aggreCheckBoxError, setAggreCheckBoxError] = useState(false);
  const [aggreCheckBox2Error, setAggreCheckBox2Error] = useState(false);

  const { currentLanguage } = useSelector(({ main }) => main);
  const mainRef = useRef();
  const dispatch = useDispatch();
  UseOutSideClick(mainRef, close);

  return (
    <div
      className={styles.main}
      ref={mainRef}>
      <div className={styles.header}>
        <div
          className={styles.backBtn}
          onClick={back}>
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.292893 6.79289C-0.0976311 7.18342 -0.0976311 7.81658 0.292893 8.20711L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41421 7.5L8.07107 1.84315C8.46159 1.45262 8.46159 0.819457 8.07107 0.428932C7.68054 0.0384078 7.04738 0.0384078 6.65685 0.428932L0.292893 6.79289ZM1 8.5H16V6.5H1V8.5Z"
              fill="#282828"></path>
          </svg>
        </div>
        <span
          className={styles.closeBtn}
          onClick={close}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1L16 16M16 1L1 16"
              stroke="#282828"></path>
          </svg>
        </span>
      </div>
      <div>
        {agreementInfo?.ico && (
          <img
            className={styles.bankLogo}
            src={"https://vlv.am/public/" + agreementInfo?.icon}
          />
        )}

        {agreementInfo.tex && (
          <div
            className={styles.bankText}
            dangerouslySetInnerHTML={{ __html: agreementInfo.text }}></div>
        )}
      </div>
      <span className={styles.title}>
        {lang[currentLanguage].order_num} {agreementInfo?.order_number}
      </span>
      <span className={styles.title}>{fullName}</span>

      <input
        className={styles.passportInput}
        type="text"
        placeholder="Անձնագիր"
        value={passportInput}
        onChange={(e) => setPassportInput(e.target.value)}
        style={
          passportInputError
            ? {
                borderColor: "#d10000",
              }
            : null
        }
      />

      <div
        className={styles.checkBoxBlock}
        onClick={() => setAggreCheckBox(!aggreCheckBox)}>
        <div
          className={styles.checkBox}
          style={
            aggreCheckBox2Error
              ? {
                  borderColor: "#d10000",
                }
              : null
          }>
          <span>
            {aggreCheckBox ? (
              <svg
                width="9"
                height="6"
                viewBox="0 1 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 2.5L3.5 5L8 0.5"
                  stroke={"#E31335"}></path>
              </svg>
            ) : null}
          </span>
        </div>
        <span className={styles.checkBoxText}>
          {lang[currentLanguage].i_have_read}
        </span>
      </div>
      <div className={styles.checkBoxBlock}>
        <div
          className={styles.checkBox}
          style={
            aggreCheckBox2Error
              ? {
                  borderColor: "#d10000",
                }
              : null
          }
          onClick={() => setAggreCheckBox2(!aggreCheckBox2)}>
          <span>
            {aggreCheckBox2 ? (
              <svg
                width="9"
                height="6"
                viewBox="0 1 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 2.5L3.5 5L8 0.5"
                  stroke={"#E31335"}></path>
              </svg>
            ) : null}
          </span>
        </div>
        <span
          className={styles.checkBoxText}
          dangerouslySetInnerHTML={{ __html: lang[currentLanguage].acba_score }}
          onClick={() => setShowCreditInfoBlock(!showCreditInfoBlock)}></span>
      </div>
      {showCreditInfoBlock ? (
        <div className={styles.creditInfoBlock}>
          <span className={styles.creditInfoBlockTitle}>
            Վարկային ամփոփ գնահատական
          </span>
          <span className={styles.creditInfoBlockDescription}>
            Տեղեկացնում ենք, որ Ձեր կողմից ներկայացված վարկային դիմումների
            քանակը բացասական ազդեցություն է ունենում Ձեր վարկային ամփոփ
            գնահատականի, մասնավորապես, ՖԱՅԿՈ սքոր գնահատականի վրա։
          </span>
        </div>
      ) : null}
      <span className={styles.addPhotoText}>
        {lang[currentLanguage].add_a_passport_photo}
      </span>

      <label className={styles.uploadBtn}>
        <input
          type="file"
          accept="image/*, application/pdf"
          multiple
          style={{ width: 0, height: 0, border: 0, outline: 0 }}
          onChange={(e) => {
            const fileArray = Array.from(e.target.files);
            if (e.target.files.length) {
              for (let i = 0; i < e.target.files.length; i++) {
                sendedFiles.push(e.target.files[i]);
              }
            }
            setUpladedFile(e.target.files);
            fileArray?.forEach((element) => {
              const reader = new FileReader();
              reader.readAsDataURL(element);
              reader.onload = function (e) {
                if (e.target.result.startsWith("data:image/png;base64"))
                  setUploadedImages((prevState) => [
                    ...prevState,
                    e.target.result,
                  ]);
              };
            });
          }}
        />
        <span>{lang[currentLanguage].upload_file}</span>
      </label>
      {uploadedFileError && (
        <span
          style={{
            color: "#d10000",
          }}>
          {lang[currentLanguage].choose_file}
        </span>
      )}

      <div className={styles.uploadedImagesRow}>
        {uploadedImages.map((img, index) => (
          <div className={styles.uploadedImgContainer}>
            <span
              className={styles.deleteImage}
              onClick={() => {
                let filteredImages = uploadedImages.filter(
                  (item, i) => index != i
                );
                setUploadedImages(filteredImages);

                sendedFiles = sendedFiles.filter((item, i) => index != i);
              }}>
              ✖
            </span>
            <img
              src={img}
              alt={""}
            />
          </div>
        ))}
      </div>
      <div className={styles.uploadFileDesc}>
        <div className={styles.uploadFileDescRow}>
          <div className={styles.step}>
            <span>Քայլ 1</span>
          </div>
          <div className={styles.desc}>
            Նկարում եք Ձեր անձնագիրը և սոց. քարտը ՝ որտեղ հստակ ընթեռնելի կերևա
            անձնագրի գլխավոր էջը(նկարով), վավերականության ժամկետը, գրանցման
            հասցենները, կամ նույնականացման քարտը(ID) 2 կողմից
          </div>
        </div>
        <div className={styles.uploadFileDescRow}>
          <div className={styles.step}>
            <span>Քայլ 2</span>
          </div>
          <div className={styles.desc}>
            Լուսանկարում եք Ձեզ անձնագրի հետ միասին
          </div>
        </div>
        <div className={styles.uploadFileDescRow}>
          <div className={styles.step}>
            <span>Քայլ 3</span>
          </div>
          <div className={styles.desc}>
            Այնուհետև բոլոր նկարները ուղարկում եք aparik@vlv.am էլ. հասցեին կամ
            ուղարկեք Viber, WhatsApp +37496018166 կամ +374 91 767117 համարին։
          </div>
        </div>
      </div>
      <div
        className={styles.buyBtn}
        onClick={() => {
          setPassportInputError(!passportInput);
          setUpladedFileError(!uploadedFile || !sendedFiles?.length);
          setAggreCheckBoxError(!aggreCheckBox);
          setAggreCheckBox2Error(!aggreCheckBox2);

          if (
            passportInput &&
            uploadedFile &&
            sendedFiles?.length &&
            aggreCheckBox &&
            aggreCheckBox2
          ) {
            const sendData = new FormData();
            sendData.append("token", process.env.REACT_APP_TOKEN);
            sendData.append("login", process.env.REACT_APP_LOGIN);
            sendData.append("password", process.env.REACT_APP_PASSWORD);
            sendData.append("credit_info_passport", passportInput);
            sendData.append("image_uploads", uploadedFile);
            sendedFiles.forEach((item, index) => {
              sendData.append("image_uploads_" + index, item);
            });
            sendData.append("order_id", agreementInfo?.order_id);
            sendData.append("img_count", uploadedImages.length);
            setPending(true);
            dispatch(
              postCreditModal2(sendData, ({ data }) => {
                setPending(false);
                setThanksModal(true);
              })
            );
          }
        }}>
        <span>{lang[currentLanguage].send}</span>
      </div>
    </div>
  );
}
