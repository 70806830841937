import React, { useState } from "react";
import LoadContent from "components/LoadContent";
import HeaderSortBlock from "screens/SearchResult/components/ProductsList/components/HeaderSortBlock";
import Spinner from "components/Spinner";
import Pageination from "components/Pageination";
import ProductCard from "components/ProductCard/newIndex";
import { useSelector } from "react-redux";
import { selectMain } from "store/selector";
import langData from "lang/data";

const NewProductList = ({
  handlePagination,
  currentPage,
  styles, 
  handleMoreProducts,
  category,
  loading, 
  handleMoreProduct,
  recordPage,
  setRecordPage,
  searchParams,
  setSearchParams
}) => {
  const { currentLanguage } = useSelector(selectMain);

  const [isToggle, setIsToggle] = useState(false); 

  const handleRecordNumber = (number) => {
    searchParams.delete("page");
    setRecordPage(number);
    handleMoreProducts(number);
    setIsToggle(false);
  };
  const handleSelectSorting = (sort) => {
    searchParams.delete("page");

    if (searchParams.get("s") === sort) {
      searchParams.delete("s");
    } else {
      searchParams.set("s", sort); 
    }
    setSearchParams(searchParams);
  };
  return (
    <>
      {loading ? (
        <LoadContent containerClassName={styles.categoryProductListLoad} />
      ) : (
        <>
          <HeaderSortBlock handleSelectSorting={handleSelectSorting}/>
          {category?.products?.length ? (
            <>
              <div className={styles.productList} >
                {category?.products?.map((item, index) => (
                  // <div className={styles.productListItem}>
                  <ProductCard
                    key={index}
                    product={item}
                    containerStyle={styles.container}
                  />
                  // </div>
                ))}
              </div>
           
              {((category?.productCount > 20 &&  category?.currentPage < category?.totalPages) ) && (
                <div className={styles.categoryMore}>
                  {loading ? (
                    <Spinner
                      width="30px"
                      height="30px"
                    />
                  ) : (
                    <span  onClick={() => handleMoreProduct()}>
                      {langData?.product?.show_more?.[currentLanguage]}
                    </span>
                  )}
                </div>
              )}

              { category?.lastPage > 1    && (
                <div className={styles.productListPagination}>
                  <Pageination
                    nPages={category?.lastPage}
                    currentPage = {currentPage} 
                    setCurrentPage = {handlePagination}
                  />
                  <div className={styles.categoryRecordPage}>
                    <span>{langData.product.record?.[currentLanguage]}:</span>
                    <div
                      onClick={() => setIsToggle(!isToggle)}
                      className={styles.categoryRecordSelect}>
                      <span>{recordPage}</span>
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.0293 5L4.99991 0.97083L9.02908 4.94145"
                          stroke="#282828"></path>
                      </svg>
                      {isToggle && (
                        <div className={styles.categorySelectList}>
                          {[20, 40, 60]?.map((item) => (
                            <span
                              onClick={() => handleRecordNumber(item)}
                              key={item}>
                              {item}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <h3 className={styles.notFound}>
              {langData.titles.SearchNull?.[currentLanguage]}
            </h3>
          )}
        </>
      )}
    </>
  );
};

export default NewProductList;
