import React, { useEffect } from "react";
import styles from "./style.module.css";

export default function ImgModal({ children, closeModal, isOpenModalImg }) {
  useEffect(() => {
    if (isOpenModalImg) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpenModalImg]);

  useEffect(() => {
    if (isOpenModalImg) {
    }
    if (document.querySelector("jdiv")) {
      if (isOpenModalImg) {
        document.querySelector("jdiv").style.display = "none";
      } else {
        document.querySelector("jdiv").style.display = "block";
      }
    }
  }, [isOpenModalImg]);

  return isOpenModalImg ? (
    <div className={styles.background}>
      <div className={styles.main}>
        <span
          className={styles.closeBtn}
          onClick={closeModal}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1L16 16M16 1L1 16"
              stroke="#282828"></path>
          </svg>
        </span>

        {children}
      </div>
    </div>
  ) : null;
}
