import React from "react";

const MagnifierMinus = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7469_37397)">
      <path
        d="M40.2593 37.983L29.2377 26.8941C31.5978 24.0755 33.0183 20.4395 33.0183 16.4695C33.0183 7.51143 25.7948 0.248047 16.8368 0.248047C7.87871 0.248047 0.616577 7.51018 0.616577 16.4682C0.616577 25.4263 7.87871 32.6884 16.8368 32.6884C20.9264 32.6884 24.6509 31.1707 27.4944 28.6748L38.4961 39.7449C38.982 40.2309 39.772 40.2309 40.258 39.7449C40.744 39.259 40.7452 38.4689 40.258 37.9817L40.2593 37.983ZM16.8156 30.1551C9.2793 30.1551 3.10873 23.9858 3.10873 16.4483C3.10873 8.91077 9.2793 2.74145 16.8156 2.74145C24.3518 2.74145 30.5224 8.91202 30.5224 16.4483C30.5224 23.9846 24.3531 30.1551 16.8156 30.1551ZM23.046 15.2022H10.5852C9.89735 15.2022 9.33911 15.7605 9.33911 16.4483C9.33911 17.1361 9.89735 17.6944 10.5852 17.6944H23.046C23.7338 17.6944 24.292 17.1361 24.292 16.4483C24.292 15.7605 23.7338 15.2022 23.046 15.2022Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7469_37397">
        <rect
          width="39.8744"
          height="39.8744"
          fill="white"
          transform="translate(0.749695 0.249268)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MagnifierMinus;
