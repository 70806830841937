import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import CloseSvg from "../../../../assets/SVG/CloseSvg";
import { useDispatch, useSelector } from "react-redux";
import MenuArrowSvg from "./assets/MenuArrowSvg";
import BackSvg from "./assets/BackSvg";
import { getMenuData } from "../../../../store/MainSlice";
import langData from "../../../../lang/data";
import { Swiper, SwiperSlide } from "swiper/react";
import checkBase64 from "../../../../helpers/checkBase64";
import { Autoplay } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";

export default function CatalogMobile({ openCatalog, setOpenCatalog }) {
  const [openCategorys, setOpenCategorys] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isDinamic, setIsDinamic] = useState(false);

  const navigate = useNavigate();

  const { menuData, headerCategorys, currentLanguage, catalogSliders } =
    useSelector(({ main }) => main);
  useEffect(() => {
    if (openCatalog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openCatalog]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!menuData?.length) {
      dispatch(getMenuData());
    }
  }, [menuData]);

  return (
    <div className={`${styles.container} ${openCatalog && styles.openMenu}`}>
      <>
        <div className={styles.headerSection}>
          <img
            src={
              currentLanguage === "hy"
                ? require("../../../../assets/logo.png")
                : "https://vlv.am/public/logos/header_ru.svg"
            }
            className={styles.headerLogo}
            alt="VLV"
          />
          <CloseSvg
            className={styles.closeBtn}
            onClick={() => setOpenCatalog(false)}
          />
        </div>
        <div className={styles.menuCategories}>
          <div
            className={styles.categories}
            onClick={() => setOpenCategorys(true)}
          >
            <span>{langData.buttons.catalog[currentLanguage]}</span>
            <MenuArrowSvg />
          </div>
          {headerCategorys.map((item, index) => {
            if (item?.icon)
              return (
                <Link
                  to={`${
                    currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/catalog/${item?.slug}`}
                  onClick={() => setOpenCatalog(false)}
                  key={index}
                  className={styles.headerCategorysItem}
                >
                  <img src={process.env.REACT_APP_STORAGE_URL + item.icon} />
                  <span>{item?.["name_" + currentLanguage]}</span>
                </Link>
              );
          })}
        </div>
        <div className={styles.brands}>
          {headerCategorys.map((item, index) => {
            if (item?.logo)
              return (
                <Link
                  to={`${currentLanguage}/brand/${item?.slug}`}
                  onClick={() => setOpenCatalog(false)}
                  key={index}
                  className={styles.brandItem1}
                >
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + item.logo}
                    alt={"VLV"}
                  />
                </Link>
              );
          })}
        </div>
      </>
      <div
        className={`${styles.categoriesSection} ${
          openCategorys && styles.categoriesSectionOpened
        }`}
      >
        <div className={styles.categoriesSectionItem}>
          <div
            className={styles.backBtn}
            onClick={() => setOpenCategorys(false)}
          >
            <BackSvg />
            <span>{langData.buttons.catalog[currentLanguage]}</span>
          </div>
          <CloseSvg
            className={styles.closeBtn}
            onClick={() => setOpenCatalog(false)}
          />
        </div>
        {menuData.map((elm, index) => {
          if (elm.from === "category") {
            return (
              <div
                className={styles.categoriesSectionItem}
                onClick={() => {
                  setActiveMenu(index);
                  setIsDinamic(false);
                }}
                key={index}
              >
                <div>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + elm.item.icon}
                  />
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/category/${elm?.item?.slug}`}
                    onClick={() => {
                      setOpenCatalog(false);
                    }}
                  >
                    {elm.item["name_" + currentLanguage]}
                  </Link>
                </div>
                <MenuArrowSvg />
              </div>
            );
          } else {
            return (
              <div
                className={`${styles.categoriesSectionItem} ${styles.menuItemDynamic}`}
                onClick={() => {
                  setActiveMenu(index);
                  setIsDinamic(true);
                }} 
                key={index}
              >
                <div>
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }category/filter/page/${elm.item.slug}?id=true`}
                    onClick={() => {
                      setOpenCatalog(false);
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        elm.item.header_category_logo
                      }
                      className={styles.menuItemDynamicImg}
                    />
                  </Link>
                </div>
                <MenuArrowSvg />
              </div>
            );
          }
        })}
      </div> 
        <div
          className={`${styles.categoriesSection}  ${
            (typeof activeMenu === "number" || isDinamic) &&
            styles.categoriesSectionOpened
          }`} 
        >
          <div className={styles.categoriesSectionItem}>
            <div
              className={styles.backBtn}
              onClick={() => {
                setActiveMenu(null);
                setIsDinamic(false);
              }}
            >
              <BackSvg />
              {activeMenu !== null && ( 
                <img
                  style={{
                    marginLeft: "10px",
                    height: isDinamic ? "40px" : "20px",
                    width: isDinamic ? "100px" : "20px",
                  }}
                  alt={
                    isDinamic
                      ? menuData[activeMenu]?.item?.title_en
                      : menuData?.[activeMenu]?.item?.name_en
                  }
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    (isDinamic
                      ? menuData?.[activeMenu]?.item?.header_category_logo
                      : menuData?.[activeMenu]?.item?.icon)
                  }
                />
              )}
              {!isDinamic && (
                <span>
                  {menuData[activeMenu]?.item["name_" + currentLanguage]}
                </span>
              )}
              
            </div>
            <CloseSvg
              className={styles.closeBtn}
              onClick={() => setOpenCatalog(false)}
            />
          </div>

          {(isDinamic
            ? Object.values(menuData[activeMenu]?.dynamic_category || {})
            : menuData[activeMenu]?.item?.sub_categories
          )?.map((elm, index) => {
            return (
              <div
                className={styles.categoriesSectionItem}
                onClick={() =>
                  elm.sub_categories?.length &&
                  !elm.sub_categories?.every((item) => !item.status) &&
                  setActiveSubMenu(index)
                }
                key={index}
              >
                <div>
                  <img src={process.env.REACT_APP_STORAGE_URL + elm.icon} />
                  <Link
                    to={
                      isDinamic
                        ? `${
                            currentLanguage !== "hy" ? "/" + currentLanguage : ""
                          }/category/filter/${elm.slug}${menuData[activeMenu]?.item?.brand_ids ? `?b=${menuData[activeMenu]?.item?.brand_ids}` : ""}`
                        : `${
                            currentLanguage !== "hy" ? "/" + currentLanguage : ""
                          }/category/filter/${elm.slug}`
                    }
                    onClick={() => {
                      setOpenCatalog(false);
                    }}
                  >
                    {elm["name_" + currentLanguage]}
                  </Link>
                </div>
                {elm.sub_categories?.length &&
                !elm.sub_categories?.every((item) => !item.status) ? (
                  <MenuArrowSvg />
                ) : null}
              </div>
            );
          })}
          {!isDinamic && !!menuData[activeMenu]?.apple?.length && (
            <div
              className={styles.categoriesSectionItem}
              onClick={() => setActiveSubMenu("apple")}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#d10000"
                  height="17px"
                  width="20px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 22.773 22.773"
                >
                  <g>
                    <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573    c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z" />
                    <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334    c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0    c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019    c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464    c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648    c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z" />
                  </g>
                </svg>
                <Link
                  to={`${
                    currentLanguage == "hy" ? "" : "/" + currentLanguage
                  }/brand/apple/category`}
                  onClick={() => {
                    setOpenCatalog(false);
                  }}
                >
                  Apple
                </Link>
              </div>

              <MenuArrowSvg />
            </div>
          )}

          {typeof activeMenu === "number" ? (
            <div style={{ width: window.innerWidth }}>
              <Swiper
                modules={[Autoplay]}
                loop
                autoplay={{ delay: 2000 }}
                speed={1000}
                slidesPerView={"auto"}
                grabCursor={true}
                className={styles.brandsContainer}
              >
                {menuData?.[activeMenu]?.brands.map((brand, index) => {
                  if (!brand?.logo) return null;
                  return (
                    <SwiperSlide className={styles.brandItem} key={index}>
                      <Link
                        to={`${
                          currentLanguage !== "hy" ? "/" + currentLanguage : ""
                        }/search/category?${
                          menuData?.[activeMenu]?.item?.slug
                        }&b=${brand?.id}`}
                        onClick={() => {
                          setOpenCatalog(false);
                        }}
                      >
                        <img
                          src={
                            checkBase64(brand?.logo)
                              ? "data:image/png;base64," + brand?.logo
                              : process.env.REACT_APP_STORAGE_URL + brand?.logo
                          }
                          className={styles.brandImg}
                        />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : null}
          {catalogSliders?.category_slider_images?.find(
            (item) => item?.category_id === menuData[activeMenu]?.item?.id
          ) ? (
            <div
              style={{ width: window.innerWidth, padding: "25px 20px 10px 20px" }}
            >
              <Swiper
                modules={[Autoplay]}
                loop
                autoplay
                slidesPerView={"auto"}
                grabCursor={true}
              >
                {catalogSliders?.category_slider_images
                  ?.filter(
                    (item) => item?.category_id === menuData[activeMenu]?.item?.id
                  )
                  ?.map((img, index) => {
                    if (!img) return null;
                    return (
                      <SwiperSlide key={index}>
                        <a href={img?.href}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              img?.["image_" + currentLanguage]
                            }
                            className={styles.bannerImg}
                          />
                        </a>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          ) : null}
        </div>
     
         
        <div
          className={`${styles.categoriesSection} ${
            (typeof activeSubMenu === "number" || activeSubMenu === "apple") &&
            styles.categoriesSectionOpened
          }`}
        >
          <div className={styles.categoriesSectionItem}>
            <div
              className={styles.backBtn}
              onClick={() => setActiveSubMenu(null)}
            >
              <BackSvg />
              {activeSubMenu && (activeSubMenu === "apple" ? (
                <svg
                  style={{ marginLeft: "10px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#d10000"
                  height="17px"
                  width="20px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 22.773 22.773"
                >
                  <g>
                    <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573    c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z" />
                    <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334    c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0    c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019    c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464    c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648    c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z" />
                  </g>
                </svg>
              ) : (
                <img
                  style={{ marginLeft: "10px" }}
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    menuData[activeMenu]?.item?.sub_categories?.[activeSubMenu]
                      ?.icon
                  }
                />
              ))}
            {activeSubMenu && (
              <Link
                to={
                  activeSubMenu === "apple"
                    ? `${
                        currentLanguage == "hy" ? "" : "/" + currentLanguage
                      }/brand/apple/category`
                    : `${
                        currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/filter/${
                        menuData[activeMenu]?.item?.sub_categories?.[
                          activeSubMenu
                        ]?.slug
                      }`
                }
                onClick={() => {
                  setOpenCatalog(false);
                }}
              >
                {activeSubMenu === "apple"
                  ? "Apple"
                  : menuData[activeMenu]?.item?.sub_categories?.[activeSubMenu]?.[
                      "name_" + currentLanguage
                    ]}
              </Link>
            )}
            </div>
            <CloseSvg
              className={styles.closeBtn}
              onClick={() => setOpenCatalog(false)}
            />
          </div>
          {(activeSubMenu !== "apple"
            ? menuData[activeMenu]?.item?.sub_categories?.[activeSubMenu]
                ?.sub_categories
            : menuData[activeMenu]?.apple
          )?.map((elm, index) => {
            if (!elm.status) return null;
            return (
              <div className={styles.categoriesSectionItem} key={index}>
                <div>
                  {elm.icon && (
                    <img src={process.env.REACT_APP_STORAGE_URL + elm.icon} />
                  )}
                  <Link
                    to={
                      activeSubMenu !== "apple"
                        ? `${
                            currentLanguage !== "hy" ? "/" + currentLanguage : ""
                          }/category/filter/${elm?.slug}`
                        : `${
                            currentLanguage == "hy" ? "" : "/" + currentLanguage
                          }/category/filter/${elm.slug}?b=73`
                    }
                    onClick={() => {
                      setOpenCatalog(false);
                    }}
                  >
                    {elm["name_" + currentLanguage]}
                  </Link>
                </div>
                {elm.sub_categories?.length ? <MenuArrowSvg /> : null}
              </div>
            );
          })}
          {typeof activeSubMenu === "number" ? (
            <div style={{ width: window.innerWidth }}>
              <Swiper
                modules={[Autoplay]}
                loop
                autoplay
                slidesPerView={"auto"}
                grabCursor={true}
                width={{ width: window.innerWidth }}
                className={styles.brandsContainer}
              >
                {menuData?.[activeMenu]?.brands.map((brand, index) => {
                  if (!brand?.logo) return null;
                  return (
                    <SwiperSlide className={styles.brandItem} key={index}>
                      <Link
                        to={`${
                          currentLanguage !== "hy" ? "/" + currentLanguage : ""
                        }/search/category?${
                          menuData?.[activeMenu]?.item?.slug
                        }&b=${brand?.id}`}
                        onClick={() => {
                          setOpenCatalog(false);
                        }}
                      >
                        <img
                          src={
                            checkBase64(brand?.logo)
                              ? "data:image/png;base64," + brand?.logo
                              : process.env.REACT_APP_STORAGE_URL + brand?.logo
                          }
                          className={styles.brandImg}
                        />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : null}
          {catalogSliders?.category_slider_images?.find(
            (item) =>
              item?.category_id ===
              menuData[activeMenu]?.item?.sub_categories?.[activeSubMenu]?.id
          ) ? (
            <div
              style={{ width: window.innerWidth, padding: "25px 20px 10px 20px" }}
            >
              <Swiper
                modules={[Autoplay]}
                loop
                autoplay
                slidesPerView={"auto"}
                grabCursor={true}
              >
                {catalogSliders?.category_slider_images
                  ?.filter(
                    (item) =>
                      item?.category_id ===
                      menuData[activeMenu]?.item?.sub_categories?.[activeSubMenu]
                        ?.id
                  )
                  ?.map((img, index) => {
                    if (!img) return null;
                    return (
                      <SwiperSlide key={index}>
                        <a href={img?.href}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              img?.["image_" + currentLanguage]
                            }
                            className={styles.bannerImg}
                          />
                        </a>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          ) : null}
        </div>
      
    </div>
    
  );
}
