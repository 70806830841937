import React, { useEffect } from "react";
import style from "./style.module.css";
import LoadContent from "../../components/LoadContent";
import { useDispatch, useSelector } from "react-redux";
import CatalogItem from "./components/CatalogItem";
import { postHeaderCategories } from "../../store/headerCategories/headerCategoriesFetch";
import langData from "../../lang/data";
import TopCatalogSlides from "./components/topCatalogSlides";
import CatalogProductSlider from "./components/CatalogProductSlider";
import FooterBaner from "../Home/components/FooterBaner";
import { getFooterBaner } from "../../store/HomeSlice";
import { useParams } from "react-router-dom";
import BanerSlider from "../../components/BanerSlider";
import FeatureCategories from "../Home/components/FeatureCategories/index";
import Brand from "../../components/Brand";
import ProductsWithSlide from "components/ProductsWithSlide";

const Catalog = () => {
  const { isLoading, error, responseList, updateSlug } = useSelector(
    (state) => state.HeaderCategoriesSlice
  );
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(({ main }) => main);
  const { slug } = useParams();

  useEffect(() => {
    if (!responseList?.length && updateSlug === null) {
      dispatch(postHeaderCategories(slug));
    } else {
      dispatch(postHeaderCategories(updateSlug));
    }
  }, [slug]);

  useEffect(() => {
    dispatch(getFooterBaner());
  }, []);

  if (error) {
    // window.location.href = "https://vlv.am/404";
    return null;
  }

  return (
    <section className={style.catalog_first_section}>
      <>
        <div className="wrapper">
          <div className={style.catalog_first_section_body}>
            <div
              className={style.catalog_left_bar}
              style={{ padding: !responseList.categories && "0" }}>
              {isLoading ? (
                <>
                  <LoadContent containerClassName={style.loadContent} />
                </>
              ) : (
                <>
                  {responseList.categories ? (
                    <>
                      {responseList.categories.map((item, index) => {
                        return (
                          <CatalogItem
                            key={item.id}
                            item={item}
                          />
                        );
                      })}
                    </>
                  ) : null}
                </>
              )}
            </div>

            <div className={style.catalog_home_slides}>
              <TopCatalogSlides
                responseList={responseList}
                isLoading={isLoading}
              />
              <FeatureCategories
                innerCategories={responseList.famous_categories}
              />
            </div>
          </div>
        </div>

        <div className={style.sales_hits_block}>
          {/* <CatalogProductSlider
            productsData={responseList.best_deal_products}
            title={langData.titles.BestDealProduct[currentLanguage]}
            isLoading={isLoading}
          /> */}

          <ProductsWithSlide
            productsData={responseList.best_deal_products?.data}
            title={langData.titles.BestDealProduct[currentLanguage]}
          />
        </div>
        {!isLoading && !responseList.slider4?.length ? null : (
          <BanerSlider
            bannerSliders={responseList.slider4}
            containerStyle={{ marginTop: "60px" }}
          />
        )}

        {/* <CatalogProductSlider
          productsData={responseList.new_products}
          isLoading={isLoading}
          title={langData.titles.TopRatingProduct[currentLanguage]}
        /> */}

        <ProductsWithSlide
          productsData={responseList.new_products?.data}
          title={langData.titles.TopRatingProduct[currentLanguage]}
        />
        {/* <CatalogProductSlider
          productsData={responseList.many_viewed_products}
          isLoading={isLoading}
          title={langData.titles.TopPicksProduct[currentLanguage]}
        /> */}
        <ProductsWithSlide
          productsData={responseList.many_viewed_products?.data}
          title={langData.titles.TopPicksProduct[currentLanguage]}
        />
        <Brand innerBrands={responseList.brands} />
      </>

      <FooterBaner />
    </section>
  );
};

export default Catalog;
