import React from "react";

const GroupSaleStarIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5 0L28.1555 4.94473L33.3504 1.65443L34.9728 7.58578L41.0055 6.39428L40.3758 12.5112L46.4315 13.5794L43.6346 19.0557L48.8955 22.2394L44.3091 26.3356L48.0647 31.2047L42.3084 33.3675L44.0514 39.2645L37.9025 39.2018L37.3976 45.3303L31.6865 43.0506L29.0019 48.5828L24.5 44.394L19.9981 48.5828L17.3135 43.0506L11.6024 45.3303L11.0975 39.2018L4.94858 39.2645L6.69162 33.3675L0.935272 31.2047L4.69086 26.3356L0.104513 22.2394L5.36544 19.0557L2.5685 13.5794L8.62425 12.5112L7.99446 6.39428L14.0272 7.58578L15.6496 1.65443L20.8445 4.94473L24.5 0Z"
      fill="#E31335"
    />
  </svg>
);

export default GroupSaleStarIcon;
