import React from "react";
import styles from "./style.module.css";

const Pageination = ({ nPages, currentPage, setCurrentPage }) => {
    if (!nPages) return null;

    const pageNumbers = [...Array(nPages + 1)?.keys()].slice(1);

    const goToNextPage = () => {
        if (currentPage !== nPages) setCurrentPage(+currentPage + 1);
    };

    const goToPrevPage = () => {
        if (currentPage !== 1) setCurrentPage(+currentPage - 1);
    };

    const renderPageNumbers = () => {
        return pageNumbers.map((pgNumber) => {
            // Логика показа страниц:
            if (
                pgNumber === 1 || // Первая страница
                pgNumber === nPages || // Последняя страница
                pgNumber === currentPage || // Текущая страница
                pgNumber === currentPage - 1 || // Предыдущая страница
                pgNumber === currentPage + 1 // Следующая страница
            ) {
                return (
                    <li
                        key={pgNumber}
                        className={`${styles.pageItem} ${
                            currentPage === pgNumber ? styles.currentPage : ""
                        }`}
                        onClick={() => setCurrentPage(pgNumber)}
                    >
                        <a>{pgNumber}</a>
                    </li>
                );
            } else if (pgNumber === currentPage - 2 || pgNumber === currentPage + 2) {
                // Отображаем многоточие только если есть скрытые страницы между текущей и первой/последней
                return <li className={styles.pageItemTwo}  key={pgNumber}>...</li>;
            } else {
                return null;
            }
        });
    };

    return (
        <nav>
            <ul className={styles.pageination}>
                {/* Кнопка "Назад" */}
                <li
                    className={styles.prevPageNextBntn}
                    onClick={goToPrevPage}
                    style={{ display: currentPage == pageNumbers[0] && "none" }}
                >
                    <span>
                        <svg
                            width="11"
                            height="18"
                            viewBox="0 0 11 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.31369 1.41443L1.76807 8.96006L9.31369 16.5057"
                                stroke="#282828"
                                strokeWidth="2"
                            ></path>
                        </svg>
                    </span>
                </li>

                {/* Рендер страниц */}
                {renderPageNumbers()}

                {/* Кнопка "Вперёд" */}
                {/* <li
                    className={styles.prevPageNextBntn}
                    onClick={goToNextPage}
                    style={{ display: currentPage == pageNumbers[0] && "none" }}
                >
                    <span>
                        <svg
                            width="11"
                            height="18"
                            viewBox="0 0 11 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.68631 16.5057L9.23193 8.96011L1.68631 1.41448"
                                stroke="#282828"
                                strokeWidth="2"
                            ></path>
                        </svg>
                    </span>
                </li> */}
            </ul>
        </nav>
    );
};

export default Pageination;
