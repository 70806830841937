import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  filters: [],
  filter: null,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    getFiltersSuccess(state, action) {
      state.isLoading = false;
      state.filters = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getFiltersSuccess, resetCart, getFilterPagination } =
  slice.actions;

// ----------------------------------------------------------------------

export function searchWsPage(WSsearchInfo, navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const categorySlug = WSsearchInfo?.category?.slug ?? 0;
      const brandId = WSsearchInfo?.brand?.id ?? 0;
      const search = WSsearchInfo?.search ?? 0;
      const brandSlug = WSsearchInfo?.brand?.slug ?? 0;
      const product_id = WSsearchInfo?.product_id ?? 0;
      const formData = new FormData();

      if (product_id) {
        navigate(`Product/${product_id}`);
        return; // Завершаем выполнение, если навигация уже произошла
      }

      if (categorySlug) {
        formData.append("slug", categorySlug);
      }
      if (brandId) {
        formData.append("b", brandId);
      }
      if (!categorySlug && !brandId && search) {
        formData.append("search", search);
        formData.append("searchInfo", 1);
      }
      if(search){
        formData.append("fs", search);
      }
   
      
      if (categorySlug) {
        const response = await axios.post(
          `https://v1.vlv.am/api/category/${categorySlug}`,
          formData
        );
        dispatch(slice.actions.getFiltersSuccess(response.data));

        if (brandId && search) {
          navigate(`category/filter/${categorySlug}?b=${brandId}?fs=${search}`);
        }else if(brandId){
          navigate(`category/filter/${categorySlug}?b=${brandId}`);
        }else if(search){
          navigate(`category/filter/${categorySlug}?fs=${search}`);
        } else {
          navigate(`category/filter/${categorySlug}`);
        }
      } else if (brandId && brandSlug) {
        navigate(`brand/${brandSlug}/category`);
      } else {
        const response = await axios.post(
          `https://v1.vlv.am/api/category/${search}`,
          formData
        );
        const res = response?.data?.products.length;

        dispatch(slice.actions.getFiltersSuccess(response.data));

        if (!res) {
          console.log("a");
          navigate('/search-null');
        } else {
          navigate(`category/filter/${WSsearchInfo?.search}?sh=1`);
        }
      }
    } catch (error) {
      console.error("Error fetching filter:", error);
      navigate('/search-null');

      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching filter")
      );
    }
  };
}


export function searchWsPageMan(slug, info,navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const categorySlug = slug ?? 0;
      const formData = new FormData();
      

      for (const [key, value] of Object.entries(info)) {
        if (value !== null && value !== "") {
          formData.append(key, value);
        }
      }
     
      if (categorySlug && !info?.sh) {
        formData.append("slug", categorySlug);
      } else{
        formData.append("search", categorySlug)    
        formData.append("searchInfo",  1); 
      }
      if (categorySlug) {
        const response = axios.post(
          `https://v1.vlv.am/api/category/${categorySlug}`,
          formData
        );
        if(!(await response)?.data?.products?.length && !Object.keys((await response)?.data?.category)?.length){ 
          navigate('/search-null') 
        }
        dispatch(slice.actions.getFiltersSuccess((await response).data));
      }
    } catch (error) {
      console.error("Error fetching filter:", error);
      navigate('/search-null')

      dispatch(
        slice.actions.hasError(error.response?.data || "Error fetching filter")
      );
    }
  };
}

 