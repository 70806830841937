import React, { useState } from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import lang from "../../../../lang/lang";
const CartHeader = () => {
  const { currentLanguage } = useSelector(({ main }) => main);
  const [openDropDown, setOpenDropDown] = useState(false);
  const handleOpenDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  return (
    <div className={style.cart_header_block}>
      <div className={`wrapper ${style.cart_header_body}`}>
        <Link
          to={`${currentLanguage !== "hy" ? "/" + currentLanguage : "/"}`}
          className={style.cart_body_back_link}>
          <svg
            width="23"
            height="8"
            viewBox="0 0 23 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H23V3.5H1V4.5Z"
              fill="#282828"></path>
          </svg>
          <span>{lang[currentLanguage].Back_to_shopping}</span>
        </Link>
        <Link
          to={`${currentLanguage !== "hy" ? "/" + currentLanguage : "/"}`}
          className={style.header_cards_logo}>
          <img
            className={style.logo}
            alt="VLV"
            src={
              currentLanguage === "hy"
                ? require("../../../../assets/logo.png")
                : "https://vlv.am/public/logos/header_ru.svg"
            }
          />
        </Link>

        <div className={style.phone_drpdown_block}>
          <div className={style.phone_drpdown_general}>
            <div className={style.phone_drpdown_svg}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.9609 15.6172L19.0234 19.5625C18.9062 20.1484 18.4375 20.5391 17.8516 20.5391C8.00781 20.5 0 12.4922 0 2.64844C0 2.0625 0.351562 1.59375 0.9375 1.47656L4.88281 0.539062C5.42969 0.421875 6.01562 0.734375 6.25 1.24219L8.08594 5.5C8.28125 6.00781 8.16406 6.59375 7.73438 6.90625L5.625 8.625C6.95312 11.3203 9.14062 13.5078 11.875 14.8359L13.5938 12.7266C13.9062 12.3359 14.4922 12.1797 15 12.375L19.2578 14.2109C19.7656 14.4844 20.0781 15.0703 19.9609 15.6172Z"
                  fill="#E31335"></path>
              </svg>
            </div>

            <a
              className={style.phone_num_call}
              href="tel:010-34-99-44">
              010-34-99-44
            </a>

            <div
              onClick={handleOpenDropDown}
              className={style.open_phone_nums}>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1L7 7L13 1"
                  stroke="#282828"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>
              </svg>
            </div>
          </div>

          <div
            className={`${style.drop_down_sub} ${
              openDropDown ? style.open : ""
            }`}>
            <a
              className="phone_num_call drp_sp"
              href="tel:010-34-99-44">
              010-34-99-44
            </a>
            <a
              className="phone_num_call drp_sp"
              href="tel:010-44-99-68">
              010-44-99-68
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartHeader;
