import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import checkBase64 from "../../../../../../helpers/checkBase64";
import { credit36Month } from "../../../../../../helpers/creditCalculator";
import langData from "../../../../../../lang/data";
import GiftSvg from "../../../../../../assets/SVG/GiftSvg";
import UseCalcPrice from "../../../../../../helpers/UseCalcPrice";
import { Link } from "react-router-dom";

export default function ComparedProduct({ product, removeCompare }) {
  const [active, setActive] = useState(0);
  const [mouseIn, setMouseIn] = useState(false);

  const productImageRef = useRef();

  const { currentLanguage, currentCurrency } = useSelector(({ main }) => main);
  return (
    <div
      className={styles.container}
      onMouseMove={() => setMouseIn(true)}
      onMouseOut={() => setMouseIn(false)}>
      <div className={styles.header}>
        <Link
          to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/brand/${
            product?.brand?.slug || product?.product?.brand?.slug
          }/category`}>
          <img
            className={styles.brandImg}
            src={
              checkBase64(product?.brand?.logo || product?.product?.brand?.logo)
                ? "data:image/png;base64," +
                  (product?.brand?.logo || product?.product?.brand?.logo)
                : process.env.REACT_APP_STORAGE_URL +
                  (product?.brand?.logo || product?.product?.brand?.logo)
            }
            alt={
              (product?.brand?.name || product?.product?.brand?.name) +
              " " +
              (product?.product_name || product?.product?.product_name)
            }
          />
        </Link>
        {removeCompare ? (
          <div
            className={styles.deleteBtn}
            onClick={() => removeCompare(product.id)}>
            <svg
              width="20"
              height="22"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.26278 11.4414C5.26278 11.6952 5.04564 11.8927 4.82849 11.8927C4.58421 11.8927 4.39421 11.6952 4.39421 11.4414V5.57516C4.39421 5.34953 4.58421 5.12391 4.82849 5.12391C5.04564 5.12391 5.26278 5.34953 5.26278 5.57516V11.4414ZM7.43421 11.4414C7.43421 11.6952 7.21706 11.8927 6.99992 11.8927C6.75564 11.8927 6.56564 11.6952 6.56564 11.4414V5.57516C6.56564 5.34953 6.75564 5.12391 6.99992 5.12391C7.21706 5.12391 7.43421 5.34953 7.43421 5.57516V11.4414ZM9.60564 11.4414C9.60564 11.6952 9.38849 11.8927 9.17135 11.8927C8.92706 11.8927 8.73706 11.6952 8.73706 11.4414V5.57516C8.73706 5.34953 8.92706 5.12391 9.17135 5.12391C9.38849 5.12391 9.60564 5.34953 9.60564 5.57516V11.4414ZM9.52421 0.865234L10.5285 2.41641H12.4285C12.7814 2.41641 13.0799 2.72664 13.0799 3.09328C13.0799 3.48813 12.7814 3.77016 12.4285 3.77016H12.2114V12.3439C12.2114 13.613 11.2342 14.6002 10.0399 14.6002H3.95992C2.73849 14.6002 1.78849 13.613 1.78849 12.3439V3.77016H1.57135C1.19135 3.77016 0.919922 3.48813 0.919922 3.09328C0.919922 2.72664 1.19135 2.41641 1.57135 2.41641H3.44421L4.44849 0.865234C4.71992 0.442188 5.20849 0.160156 5.72421 0.160156H8.24849C8.76421 0.160156 9.25278 0.442188 9.52421 0.865234ZM5.01849 2.41641H8.95421L8.43849 1.62672C8.41135 1.57031 8.32992 1.51391 8.24849 1.51391H5.72421C5.64278 1.51391 5.56135 1.57031 5.53421 1.62672L5.01849 2.41641ZM3.09135 12.3439C3.09135 12.8516 3.47135 13.2464 3.95992 13.2464H10.0399C10.5014 13.2464 10.9085 12.8516 10.9085 12.3439V3.77016H3.09135V12.3439Z"
                fill="#323232"></path>
            </svg>
          </div>
        ) : null}
      </div>

      <div
        style={{
          width: "100%",
          height: "176px",
          position: "relative",
        }}>
        <div className={styles.stickersBlock}>
          {(product?.gift_images || product?.product?.gift_images)?.length ? (
            <div className={styles.giftBox}>
              <GiftSvg />
              <div className={styles.giftHovered}>
                <div className={styles.giftHoveredMain}>
                  <a
                    href={
                      process.env.REACT_APP_SERVER_URL +
                      "Product/" +
                      (product?.gift_images?.[0]?.product_id ||
                        product?.product?.gift_images?.[0]?.product_id)
                    }>
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        (product?.gift_images?.[0]?.image ||
                          product.product?.gift_images?.[0]?.image)
                      }
                      className={styles.giftHoveredImg}
                      alt="gift"
                    />
                  </a>
                </div>
              </div>
            </div>
          ) : null}
          {(product?.sticker || product?.product?.sticker)?.length ? (
            <img
              className={styles.stickerImg}
              src={
                process.env.REACT_APP_STORAGE_URL +
                (product?.sticker || product?.product?.sticker)?.[0]?.[
                  "image_" + currentLanguage
                ]
              }
            />
          ) : null}
        </div>
        <Link
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            marginBottom: "5px",
          }}
          ref={productImageRef}
          to={
            window.innerWidth > 992 &&
            `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/Product/${
              product?.seller_product_id || product?.product?.id
            }`
          }>
          {(product?.media_ids || product?.media_ids
            ? product?.media_ids && product?.media_ids?.split(",")?.length
              ? product?.media_ids?.split(",")
              : product?.gallary_images_api?.length
              ? product?.gallary_images_api
              : Array.from({ length: 1 })
            : product?.product?.media_ids &&
              product?.product?.media_ids?.split(",")?.length
            ? product?.product?.media_ids?.split(",")
            : product?.product?.gallary_images_api?.length
            ? product?.product?.gallary_images_api
            : Array.from({ length: 1 })
          ).map((_, index) => (
            <div
              key={index}
              style={{
                cursor: "pointer",
                background: "transparent",
                width: "100%",
                height: "100%",
                marginRight: "5px",
                borderBottom:
                  "2px solid " +
                  (index === active ? "rgb(227, 19, 53)" : "#d9d9d9"),
                zIndex: 99,
                opacity: +mouseIn,
              }}
              onClick={() => setActive(index)}
              onMouseMove={() => setActive(index)}
            />
          ))}
        </Link>

        {product?.media_ids || product?.product?.media_ids ? (
          (product?.media_ids || product?.product?.media_ids)
            ?.split(",")
            ?.map((item, index) => (
              <div
                style={{
                  zIndex: index,
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  paddingBottom: "5px",
                }}
                key={index}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    opacity: active === index ? 1 : 0,
                    paddingBottom: "7px",
                  }}
                  alt={
                    (product?.brand?.name || product?.product?.brand?.name) +
                    " " +
                    (product?.product_name || product?.product?.product_name)
                  }
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    (
                      product?.gallary_images_api ||
                      product?.product?.gallary_images_api
                    )?.find((galery) => galery?.media_id === +item)
                      ?.images_source
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../../../../assets/defaultImg.png");
                  }}
                />
              </div>
            ))
        ) : (
            product?.gallary_images_api || product?.product?.gallary_images_api
          )?.length ? (
          (
            product?.gallary_images_api || product?.product?.gallary_images_api
          )?.map((item, index) => (
            <div
              style={{
                zIndex: index,
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                paddingBottom: "5px",
              }}
              key={index}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  opacity: active === index ? 1 : 0,
                  paddingBottom: "7px",
                }}
                alt={
                  (product?.brand?.name || product?.product?.brand?.name) +
                  " " +
                  (product?.product_name || product?.product?.product_name)
                }
                src={process.env.REACT_APP_STORAGE_URL + item?.images_source}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../../../../assets/defaultImg.png");
                }}
              />
            </div>
          ))
        ) : (
          <div
            style={{
              zIndex: 1,
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              paddingBottom: "10px",
            }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              alt={
                (product?.brand?.name || product?.product?.brand?.name) +
                " " +
                (product?.product_name || product?.product?.product_name)
              }
              src={
                process.env.REACT_APP_STORAGE_URL +
                (product?.thumbnail_image_source ||
                  product?.product?.thumbnail_image_source)
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = require("../../../../../../assets/defaultImg.png");
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.descContainer}>
        <Link
          to={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/Product/${
            product?.seller_product_id || product?.id || product?.product?.id
          }`}>
          {product?.skus?.[0].promo_price ||
          (product?.online_price && product?.online_selling_price) ? (
            <>
              <span className={styles.newPrice}>
                {UseCalcPrice(
                  product?.skus?.[0].promo_price ||
                    (product?.online_price && product?.online_selling_price),
                  currentCurrency
                )}
              </span>
              <div className={styles.oldPriceBlock}>
                <span className={styles.oldPrice}>
                  {UseCalcPrice(
                    product?.skus?.[0].selling_price,
                    currentCurrency
                  )}
                </span>
                <span className={styles.oldPriceLine} />
              </div>
            </>
          ) : (
            <span className={styles.price}>
              {UseCalcPrice(product?.skus?.[0].selling_price, currentCurrency)}
            </span>
          )}

          {product?.cashback ? (
            <div className={styles.cashbackContainer}>
              <span className={styles.cashbackText}>Cashback</span>
              <span
                className={styles.cashbackPrice}
                style={{ marginLeft: "5px" }}>
                {UseCalcPrice(product?.cashback, currentCurrency)}
              </span>
            </div>
          ) : null}
          {credit36Month(
            product?.promo_price ||
              (product?.online_price && product?.online_selling_price) ||
              product?.skus[0]?.selling_price
          ) ? (
            <div className={styles.priceMounth}>
              {UseCalcPrice(
                credit36Month(
                  product?.promo_price ||
                    (product?.online_price && product?.online_selling_price) ||
                    product?.skus[0]?.selling_price
                ),
                currentCurrency
              )}{" "}
              / {langData.month[currentLanguage]}
            </div>
          ) : (
            <div style={{ height: "24px" }} />
          )}
          <p className={styles.productName}>
            {(product?.brand?.name || product?.product?.brand?.name) +
              " " +
              (product?.product_name || product?.product?.product_name)}
          </p>
        </Link>
      </div>
    </div>
  );
}
