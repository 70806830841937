const { createSlice } = require("@reduxjs/toolkit");

const successOrderSlice = createSlice({
  name: "orderModal",
  initialState: {
    isOpenSuccessOrder: false,
    productId: null,
  },
  reducers: {
    setOpenSuccessOrder: (state, { payload }) => {
      state.isOpenSuccessOrder = payload;
    },
  },
});
export default successOrderSlice.reducer;
export const { setOpenSuccessOrder } = successOrderSlice.actions;